import { toObject } from "../helpers/MapHelper"
import { ScheduleMap } from "../helpers/ScheduleMapHelper"
import LocalityMutationType from "../types/LocalityMutationType"
import LocalityType, { StaticLocalityType } from "../types/LocalityType"
import ShiftMutationType from "../types/ShiftMutationType"
import ShiftType, { StaticShiftType } from "../types/ShiftType"
import { ValidationMessageType } from "../types/ValidationMessageType"
import api from "./Api"

export function getScheduleForDate(location: number, date: number) {
    return api.get<{
        shifts: ShiftType[]
        shiftMutations: ShiftMutationType[]
        localities: LocalityType[]
        localityMutations: LocalityMutationType[]
        messages: ValidationMessageType[]
        errorMessage?: string
    }>(`/schedule/?location=${location}&date=${date}`)
}

export function getPersonalShiftsAndLocalities(locations: number[], user: number, date: number, days: number) {
    let url = `/schedule/personal/?locations=${locations}&date=${date}&days=${days}`
    if (user) {
        url += `&user=${user}`
    }
    return api.get<{ shifts: StaticShiftType[]; shiftMutations: ShiftMutationType[]; localities: StaticLocalityType[]; localityMutations: LocalityMutationType[] }>(url)
}

export function storeShiftsAndLocalities(newScheduleMutations: ScheduleMap, sendNotifications: boolean, handleAbsenceRequest?: number) {
    return api.post(`/schedule/store/`, {
        newMutations: toObject(newScheduleMutations),
        handleAbsenceRequest: handleAbsenceRequest,
        sendNotifications: sendNotifications,
    })
}
