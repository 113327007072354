import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { useRolesForSelectedLocation, useUsersForSelectedLocation } from "../../../contexts/UserSettingsContext"
import { ROLE_TYPE_REGULAR } from "../../../helpers/Constants"
import { dateFromDjango, getDaysString } from "../../../helpers/DaysHelper"
import { setOptionalError } from "../../../helpers/FormHelper"
import { getRoleOptionsForType } from "../../../helpers/RolesHelper"
import { toScheduleMapFromGeneratedMutations } from "../../../helpers/ScheduleMapHelper"
import { getNonExternalEmployableUsers, getUserOptions } from "../../../helpers/UsersHelper"
import { createTaskShiftAdditions, TaskShiftAdditionsResult, TaskType } from "../../../services/Task"
import DayOptions from "../../DayOptions"
import InlineFormMultiSelect from "../../form/InlineFormMultiSelect"
import GeneratedMutations from "../breakPlanner/GeneratedMutationsResult"
import TaskProgress from "../breakPlanner/TaskProgress"
import styles from "./ShiftGenerator.module.scss"

interface Inputs {
    days: string
    users: string[]
    roles: string[]
}

interface Props {
    visible: boolean
    close: () => void
}

const ShiftGenerator: FC<Props> = ({ visible, close }) => {
    const { applyMutations } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()
    const allUsers = useUsersForSelectedLocation()
    const allRoles = useRolesForSelectedLocation()

    const location = useMemo(() => editorState.location, [editorState.location])
    const enabledDays = useMemo(() => location.enabledDays, [location])
    const shiftTemplate = useMemo(() => editorState.shiftTemplate!, [editorState.shiftTemplate])
    const validFrom = useMemo(() => (shiftTemplate ? dateFromDjango(shiftTemplate!.validFrom) : new Date()), [shiftTemplate])
    const weekCycle = useMemo(() => (shiftTemplate ? shiftTemplate.weekCycle : 1), [shiftTemplate])
    const newScheduleMutations = useMemo(() => editorState.newScheduleMutations, [editorState.newScheduleMutations])
    const roleOptions = useMemo(() => (allRoles ? getRoleOptionsForType(allRoles, ROLE_TYPE_REGULAR) : []), [allRoles])
    const userOptions = useMemo(() => getUserOptions(getNonExternalEmployableUsers(allUsers, validFrom)), [allUsers, validFrom])

    const {
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<Inputs>({
        defaultValues: {
            days: getDaysString(weekCycle),
            users: getNonExternalEmployableUsers(allUsers, validFrom).map((u) => u.id.toString()),
            roles: getRoleOptionsForType(allRoles, ROLE_TYPE_REGULAR).map((r) => r.id.toString()),
        },
    })

    const [task, setTask] = useState<TaskType<TaskShiftAdditionsResult>>()

    useEffect(() => setValue("days", getDaysString(weekCycle)), [setValue, weekCycle])

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ days, users, roles }) => {
            const required = "Dit veld is vereist."
            const validDate = "Vul een geldige datum in"
            let newDaysError: string | undefined = undefined
            let newUsersError: string | undefined = undefined
            let newRolesError: string | undefined = undefined

            let hasIncorrect = false
            let hasSelected = false
            for (let i = 0; i < weekCycle * 7; i++) {
                if (enabledDays[i % 7] === "1" && days[i] === "1") {
                    hasSelected = true
                }
                if (days[i] !== "1" && days[i] !== "0") {
                    hasIncorrect = true
                }
            }
            if (hasIncorrect || !hasSelected) {
                newDaysError = required
            }
            if (!users || users.length === 0) {
                newUsersError = required
            }
            if (!roles || roles.length === 0) {
                newRolesError = required
            }

            setOptionalError(setError, "days", newDaysError)
            setOptionalError(setError, "users", newUsersError)
            setOptionalError(setError, "roles", newRolesError)

            if (newDaysError || newUsersError || newRolesError) {
                // Show error, do nothing
            } else {
                createTaskShiftAdditions({
                    location: location.id,
                    shiftTemplate: shiftTemplate.id,
                    newShiftMutations: newScheduleMutations,
                    days,
                    users: users.map((u) => parseInt(u)),
                    roles: roles.map((r) => parseInt(r)),
                }).then((response) => setTask(response.data))
            }
        },
        [location, shiftTemplate, newScheduleMutations, setError, setTask]
    )

    const apply = useCallback(() => {
        const scheduleMap = toScheduleMapFromGeneratedMutations(task!.result.mutations)
        applyMutations(scheduleMap)
        close()
        setTask(undefined)
        reset()
    }, [task, applyMutations, close, setTask, reset])

    const resetTask = useCallback(() => setTask(undefined), [setTask])

    const days = watch("days")
    const setDays = useCallback(
        (days: string) => {
            setValue("days", days)
            clearErrors("days")
        },
        [setValue, clearErrors]
    )

    const users = watch("users")
    const setUsers = useCallback(
        (users: string[]) => {
            setValue("users", users)
            clearErrors("users")
        },
        [setValue, clearErrors]
    )

    const roles = watch("roles")
    const setRoles = useCallback(
        (roles: string[]) => {
            setValue("roles", roles)
            clearErrors("roles")
        },
        [setValue, clearErrors]
    )

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Automatisch aanvullen {task?.result?.mutations ? `(${task.result.mutations.length})` : null}</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {task ? (
                    <>
                        {task.done ? <GeneratedMutations task={task} roles={allRoles} mode="DAYS" weekCycle={weekCycle} /> : <TaskProgress initialTask={task} setTask={setTask} />}
                        <div className="mt-4">
                            <Button type="button" onClick={resetTask}>
                                Vorige
                            </Button>
                            {task.done ? (
                                <Button type="button" onClick={apply} className="ms-2">
                                    Toepassen
                                </Button>
                            ) : null}
                        </div>
                    </>
                ) : (
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <table className="form-inline-table">
                            <tbody>
                                <tr>
                                    <td className="form-inline-name">
                                        <span className="form-inline-title">Dagen:</span>
                                    </td>
                                    <td>
                                        <Form.Group>
                                            <div className="d-inline-block form-inline-input-group px-2">
                                                <DayOptions enabledDays={enabledDays} weekCycle={weekCycle} selectedDays={days} disabled={false} setSelectedDays={setDays} />
                                            </div>
                                            <Form.Control type="hidden" isInvalid={!!errors.days} />
                                            <Form.Control.Feedback type="invalid">{errors.days?.message}</Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Medewerkers:</span>
                                    </td>
                                    <td>
                                        <div className="container-scroll" style={{ maxHeight: "150px", paddingTop: "0.375rem" }}>
                                            <InlineFormMultiSelect id="users" options={userOptions} values={users} onChange={setUsers} toggleAllEnabled={true} />
                                        </div>
                                        <Form.Control type="hidden" isInvalid={!!errors.users?.message} />
                                        <Form.Control.Feedback type="invalid">{errors.users?.message}</Form.Control.Feedback>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Taken:</span>
                                    </td>
                                    <td>
                                        <div className="container-scroll" style={{ maxHeight: "150px", paddingTop: "0.375rem" }}>
                                            <InlineFormMultiSelect id="roles" options={roleOptions} values={roles} onChange={setRoles} toggleAllEnabled={true} />
                                        </div>
                                        <Form.Control type="hidden" isInvalid={!!errors.roles?.message} />
                                        <Form.Control.Feedback type="invalid">{errors.roles?.message}</Form.Control.Feedback>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Button type="submit" data-cy="createShiftGeneration">
                            Vul aan
                        </Button>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ShiftGenerator
