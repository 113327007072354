import { useCallback, useContext, useEffect, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import QRCode from "react-qr-code"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { setOptionalError } from "../helpers/FormHelper"
import { getTOTPUrl } from "../helpers/TOTPHelper"
import { confirmInitialTOTPDevice, loadInitialTOTPDevice } from "../services/User"
import styles from "./LoginMfaNew.module.scss"

interface Inputs {
    otp_device: string
    otp_token: string
}

const LoginMfaNewPage = () => {
    const navigate = useNavigate()
    const { userSettings, reloadUserSettings } = useContext(UserSettingsContext)
    const isAuthenticated = useIsAuthenticated()
    const { data: device } = useQuery("unconfirmedTOTPDevice", loadInitialTOTPDevice)
    const {
        register,
        reset,
        setValue,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "otp_device", data.otp_device)
            setOptionalError(setError, "otp_token", data.otp_token)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ otp_device, otp_token }) => {
            confirmInitialTOTPDevice(otp_device, otp_token).then(reloadUserSettings).catch(onFailure)
        },
        [reloadUserSettings, reset, onFailure]
    )

    useEffect(() => {
        if (!userSettings) {
            return
        } else if (!userSettings.authenticated) {
            navigate("/login")
        } else if (userSettings.totpDevices.length > 0) {
            navigate("/inloggen/mfa")
        }
    }, [userSettings])

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (device) {
            setValue("otp_device", device.persistentId)
        }
    }, [device])

    const qrPath = useMemo(() => getTOTPUrl(device), [device])

    return (
        <FullPageCard>
            <div>
                <h2 className="h2">Bevestig 2FA apparaat</h2>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>Registreer je apparaat door de QR code te scannen in een TOTP authenticatie app zoals Google Authenticator.</Form.Label>
                    <Form.Select {...register("otp_device")} disabled={true}>
                        {device ? (
                            <option key={device.persistentId} value={device.persistentId}>
                                {device.name}
                            </option>
                        ) : null}
                    </Form.Select>
                    <div className={`mt-3 ${styles.qrContainer}`}>{qrPath ? <QRCode value={qrPath} className="w-100 h-100" /> : null}</div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Bevestig je apparaat door eenmalig een gegenereerde code hier vanuit je TOTP authenticatie app in te vullen.</Form.Label>
                    <Form.Control type="text" inputMode="numeric" pattern="[0-9]*" {...register("otp_token")} isInvalid={!!errors.otp_token} className={styles.codeInput} autoFocus />
                    <Form.Control.Feedback type="invalid">{errors.otp_token?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 flex-row">
                    <Button data-cy="login-button" type="submit">
                        Bevestig
                    </Button>
                    <small className="end">
                        <Link to="/uitloggen" className="fw-bold">
                            Terug
                        </Link>
                    </small>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid">{errors.root?.message}</Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default LoginMfaNewPage
