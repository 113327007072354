import { FC } from "react"
import { useInitMode, useIsAuthenticated } from "../../contexts/UserSettingsContext"
import { UserInitMode } from "../../types/UserType"
import Menu from "./Menu"

const MenuFixed: FC = () => {
    const isAuthenticated = useIsAuthenticated()
    const initMode = useInitMode()

    return isAuthenticated && initMode >= UserInitMode.STARTED_TOUR ? (
        <nav className="d-none d-lg-block mt-4 pe-0">
            <Menu />
        </nav>
    ) : null
}

export default MenuFixed
