import { useCallback, useContext, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"
import { UserSettingsContext } from "../contexts/UserSettingsContext"
import { setOptionalError } from "../helpers/FormHelper"
import { login } from "../services/User"

interface Inputs {
    username: string
    password: string
}

const Login = () => {
    const navigate = useNavigate()
    const { userSettings, reloadUserSettings } = useContext(UserSettingsContext)
    const {
        register,
        reset,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "username", data.username)
            setOptionalError(setError, "password", data.password)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ username, password }) => {
            login(username, password).then(reloadUserSettings).catch(onFailure)
        },
        [reloadUserSettings, reset, onFailure]
    )

    useEffect(() => {
        if (!userSettings) {
            return
        }
        if (userSettings.user) {
            navigate("/dashboard")
        } else if (userSettings.authenticated && !userSettings.verified) {
            navigate("/inloggen/mfa")
        }
    }, [userSettings])

    return (
        <FullPageCard>
            <div>
                <h1 className="h2">Welkom terug!</h1>
                <p>Log in om te beginnen met Rooster Planner</p>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>E-mailadres</Form.Label>
                    <Form.Control type="email" autoComplete="email" {...register("username")} isInvalid={!!errors.username} data-cy="email" autoFocus />
                    <Form.Control.Feedback type="invalid" data-cy="email_errors">
                        {errors.username?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="w-100 d-flex justify-content-between align-items-center">
                        Wachtwoord
                        <Link data-cy="password-reset" to="/wachtwoord-vergeten">
                            Wachtwoord vergeten?
                        </Link>
                    </Form.Label>
                    <Form.Control type="password" {...register("password")} isInvalid={!!errors.password} data-cy="password" />
                    <Form.Control.Feedback type="invalid" data-cy="password_errors">
                        {errors.password?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 flex-row">
                    <Button data-cy="login-button" type="submit">
                        Log&nbsp;in
                    </Button>
                    <small className="end">
                        <span className="text-muted">Nog geen account? </span>
                        <Link data-cy="register" to="/registreren" className="fw-bold">
                            Registreer
                        </Link>
                    </small>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default Login
