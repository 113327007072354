import { ChangeEvent, ChangeEventHandler, useCallback, useContext, useMemo } from "react"
import { Badge, Button, Dropdown, Form, Nav } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useTabs } from "../contexts/TabsContext"
import { useStartTour } from "../contexts/TourContext"
import { UserSettingsContext, useCurrentCompany, useInitMode, useIsAdmin, useIsAuthenticated, useIsStaff, useLocations, useOptionalCurrentUser } from "../contexts/UserSettingsContext"
import { dateFromDjango, isoPrint } from "../helpers/DaysHelper"
import logo from "../images/logo.png"
import { UserInitMode } from "../types/UserType"
import AdminsOnline from "./AdminsOnline"
import styles from "./TopBar.module.scss"

export default function TopBar() {
    const navigate = useNavigate()
    const currentUser = useOptionalCurrentUser()
    const currentCompany = useCurrentCompany()
    const locations = useLocations()
    const isAuthenticated = useIsAuthenticated()
    const { setMenuOpen } = useTabs()
    const initMode = useInitMode()
    const startTour = useStartTour()
    const isAdmin = useIsAdmin()
    const isStaff = useIsStaff()
    const { selectedLocationId, selectLocation } = useContext(UserSettingsContext)

    const selectedLocationValue = useMemo(() => selectedLocationId?.toString() ?? "", [selectedLocationId])

    const onChangeLocationValue: ChangeEventHandler<HTMLSelectElement> = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLSelectElement
            selectLocation(parseInt(target.value))
        },
        [selectLocation]
    )

    return isAuthenticated && initMode >= UserInitMode.STARTED_TOUR ? (
        <div className="navbar">
            <Nav className={`d-none d-lg-block ${styles.logoContainer}`}>
                <img className={`logo ${styles.logo}`} src={logo} alt="logo" />
                <div className="d-none d-xl-inline-block">
                    <span className={`${styles.logoText}`}>Rooster Planner</span>
                </div>
            </Nav>
            <Nav className="d-block d-lg-none">
                <Button onClick={() => setMenuOpen(true)} variant="link">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
                        <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path>
                    </svg>
                </Button>
            </Nav>
            {currentCompany?.activeUntil ? (
                <div>
                    <Badge bg="danger">Proefperiode tot: {isoPrint(dateFromDjango(currentCompany.activeUntil))}</Badge>
                    {isAdmin && initMode === UserInitMode.STARTED_TOUR ? (
                        <Button variant="link" onClick={startTour}>
                            <i className="fa-regular fa-circle-play me-1"></i>Uitleg
                        </Button>
                    ) : null}
                </div>
            ) : null}
            <div className="d-flex justify-content-end align-items-center">
                {locations.length > 1 ? (
                    <Form.Select value={selectedLocationValue} onChange={onChangeLocationValue} className="form-select-inline mx-2" data-cy="locationSelector">
                        {locations.map(({ id, name }) => (
                            <option key={`${id}`} value={`${id}`}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                ) : null}
                {isAdmin ? <AdminsOnline /> : null}
                <Dropdown align="end">
                    <Dropdown.Toggle variant="link" data-cy="user-dropdown-toggle">
                        {currentUser?.firstName} {currentUser?.lastName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isAdmin ? <Dropdown.Item onClick={() => navigate("/instellingen")}>Instellingen</Dropdown.Item> : null}
                        <Dropdown.Item onClick={() => navigate("/mijn-account")}>Mijn account</Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/wachtwoord-wijzigen")}>Wijzig wachtwoord</Dropdown.Item>
                        {isStaff ? <Dropdown.Item onClick={() => window.location.replace(process.env.REACT_APP_ADMIN_APP_URL!)}>Naar admin</Dropdown.Item> : null}
                        <Dropdown.Item onClick={() => navigate("/uitloggen")} data-cy="logout">
                            Log uit
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    ) : null
}
