import { FC } from "react"
import { Form } from "react-bootstrap"
import { FieldErrors, UseFormRegister } from "react-hook-form"
import { UserEditInputs, UserEditMode } from "../../pages/users/UserEditPage"
import InfoPopover from "../InfoPopover"
import UserTabErrors from "./UserTabErrors"
import { UserTab } from "./UserTabs"
import UserTypeOptions from "./UserTypeOptions"

interface Props {
    selectedTab: UserTab
    register: UseFormRegister<UserEditInputs>
    mode: UserEditMode
    errors: FieldErrors<UserEditInputs>
    tabErrors: UserTab[]
}

const UserDetailsForm: FC<Props> = ({ selectedTab, register, errors, mode, tabErrors }) => {
    if (selectedTab !== "Profiel") {
        return null
    }

    return (
        <div>
            <Form.Group className="mb-3">
                <Form.Label>Voornaam</Form.Label>
                <Form.Control size="lg" type="text" {...register("firstName")} isInvalid={!!errors.firstName} />
                <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Achternaam</Form.Label>
                <Form.Control size="lg" type="text" {...register("lastName")} isInvalid={!!errors.lastName} />
                <Form.Control.Feedback type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>E-mailadres</Form.Label>
                <Form.Control size="lg" type="email" {...register("email")} isInvalid={!!errors.email} disabled={mode === "update-my"} />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
            {mode !== "update-my" ? (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Accounttype</Form.Label>
                        <Form.Select size="lg" {...register("type")} isInvalid={!!errors.type}>
                            <UserTypeOptions />
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                    </Form.Group>
                    {mode === "create" ? (
                        <Form.Group className="mb-3">
                            <Form.Label>Verstuur direct uitnodigingsmail om wachtwoord in te stellen</Form.Label>
                            <Form.Check {...register("sendInvitation")} isInvalid={!!errors.sendInvitation} />
                            <Form.Control.Feedback type="invalid">{errors.sendInvitation?.message}</Form.Control.Feedback>
                        </Form.Group>
                    ) : (
                        <Form.Group className="mb-3">
                            <Form.Label>Actief</Form.Label>
                            <InfoPopover
                                id="isActivePopover"
                                body="Een account wordt geactiveerd via de activatiemail. Indien een account niet actief is kun je met de knop “Stuur uitnodiging” een nieuwe activatiemail versturen. Gebruikers die via SSO inloggen worden automatisch geactiveerd bij het eerste gebruik van de applicatie. Inactieve gebruikers kunnen niet (meer) inloggen."
                            />
                            <Form.Check {...register("isActive")} isInvalid={!!errors.isActive} disabled={true} />
                            <Form.Control.Feedback type="invalid">{errors.isActive?.message}</Form.Control.Feedback>
                        </Form.Group>
                    )}
                </>
            ) : null}
            <UserTabErrors tabErrors={tabErrors} except="Profiel" />
        </div>
    )
}

export default UserDetailsForm
