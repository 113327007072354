import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import FormSuccessMessage from "../../components/form/FormSuccessMessage"
import RootFeedback from "../../components/form/RootFeedback"
import RegularPage from "../../components/page/RegularPage"
import UserDetailsForm from "../../components/user/UserDetailsForm"
import UserLocationsForm from "../../components/user/UserLocationsForm"
import UserRolesForm from "../../components/user/UserRolesForm"
import UserTabs, { UserTab, userTabs } from "../../components/user/UserTabs"
import UserTimeForm from "../../components/user/UserTimeInput/UserTimeForm"
import { useTabs } from "../../contexts/TabsContext"
import { useCurrentCompany, useRolesForSelectedLocation, UserSettingsContext, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { dateFromDjango, dateToDjango, getSlot, getTimeString, timeToDate } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { initAvailabilities, initRolePreferences } from "../../helpers/UsersHelper"
import { createUser, deactivate, invite, updateMy, updateUser } from "../../services/User"
import { EditUserAvailabilityType, RolePreferenceType, UserDetailedType, UserTypeType } from "../../types/UserType"

export type UserEditMode = "create" | "update" | "update-my"

interface UserEditPageProps {
    mode: UserEditMode
    id?: any
    user?: UserDetailedType
}

export interface UserEditInputs {
    firstName: string
    lastName: string
    email: string
    type: UserTypeType
    isActive: boolean
    sendInvitation: boolean
    locations: number[]
    employableFrom?: Date
    employableTo?: Date
    weekCycle: number
    availabilities: EditUserAvailabilityType[]
    rolePreferences: RolePreferenceType[]
}

const UserEditPage: FC<UserEditPageProps> = ({ mode, user }) => {
    const currentCompany = useCurrentCompany()
    const location = useSelectedLocation()
    const roles = useRolesForSelectedLocation()
    const {
        register,
        watch,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        handleSubmit,
    } = useForm<UserEditInputs>({
        defaultValues: {
            firstName: user ? user.firstName : "",
            lastName: user ? user.lastName : "",
            email: user ? user.email : "",
            type: user ? user.type : "REGULAR",
            isActive: user ? user.isActive : false,
            sendInvitation: false,
            locations: user ? user.locations : [location.id],
            employableFrom: user && user.employableFrom ? dateFromDjango(user.employableFrom) : undefined,
            employableTo: user && user.employableTo ? dateFromDjango(user.employableTo) : undefined,
            weekCycle: user ? user.weekCycle : 1,
            availabilities: user ? user.availabilities : initAvailabilities(currentCompany!, 1),
            rolePreferences: user ? user.rolePreferences : initRolePreferences(roles!),
        },
    })

    const firstName = watch("firstName")
    const lastName = watch("lastName")
    const isActive = watch("isActive")

    const { setActiveTab } = useTabs()
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)

    const [succeededId, setSucceededId] = useState(0)
    const [tabErrors, setTabErrors] = useState<UserTab[]>([])
    const [selectedTab, setSelectedTab] = useState<UserTab>(userTabs[0])

    useEffect(() => {
        if (user) {
            setValue("firstName", user.firstName)
            setValue("lastName", user.lastName)
            setValue("email", user.email)
            setValue("type", user.type)
            setValue("isActive", user.isActive)
            setValue("sendInvitation", false)
            setValue("locations", user.locations)
            setValue("employableFrom", user.employableFrom ? dateFromDjango(user.employableFrom) : undefined)
            setValue("employableTo", user.employableTo ? dateFromDjango(user.employableTo) : undefined)
            setValue("weekCycle", user.weekCycle)
            setValue(
                "availabilities",
                user.availabilities.map(({ day, startSlot, endSlot }) => {
                    return { day, startTime: getTimeString("start", startSlot), endTime: getTimeString("end", endSlot) }
                })
            )
            setValue("rolePreferences", user.rolePreferences)
        }
    }, [user, setValue])

    const onSuccessAction = useCallback(() => {
        setSucceededId(succeededId + 1)
        setTabErrors([])
    }, [setSucceededId, succeededId, setTabErrors])

    const onSuccess = useCallback(() => {
        reloadUserSettings()
        navigate("/medewerkers")
    }, [reloadUserSettings, navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            const tabErrors: UserTab[] = []

            setOptionalError(setError, "firstName", data.firstName)
            setOptionalError(setError, "lastName", data.lastName)
            setOptionalError(setError, "email", data.email)
            setOptionalError(setError, "type", data.type)
            setOptionalError(setError, "isActive", data.isActive)
            setOptionalError(setError, "sendInvitation", data.sendInvitation)
            if (data.firstName || data.lastName || data.email || data.type || data.isActive || data.sendInvitation) {
                tabErrors.push("Profiel")
            }

            setOptionalError(setError, "locations", data.locations)
            if (data.locations) {
                tabErrors.push("Locaties")
            }

            setOptionalError(setError, "employableFrom", data.employableFrom)
            setOptionalError(setError, "employableTo", data.employableTo)
            setOptionalError(setError, "weekCycle", data.weekCycle)
            setOptionalError(setError, "availabilities", data.availabilities)
            if (data.employableFrom || data.employableTo || data.weekCycle || data.availabilities) {
                tabErrors.push("Werktijden")
            }

            setOptionalError(setError, "rolePreferences", data.rolePreferences)
            if (data.rolePreferences) {
                tabErrors.push("Taken")
            }

            setTabErrors(tabErrors)
            setOptionalError(setError, "root", data.nonFieldErrors || ["Er is iets misgegaan"])
            setSucceededId(0)
        },
        [setError, setTabErrors, setSucceededId]
    )

    const inviteUser = useCallback(() => {
        invite(user!.id)
            .then(onSuccessAction)
            .catch((error) => onFailure(error))
    }, [user, onSuccessAction, onFailure])

    const deactivateUser = useCallback(() => {
        deactivate(user!.id)
            .then(onSuccess)
            .catch((error) => onFailure(error))
    }, [user, onSuccess, onFailure])

    const onSubmit = useCallback(
        ({ firstName, lastName, email, employableFrom, employableTo, type, sendInvitation, weekCycle, locations, rolePreferences, availabilities }: UserEditInputs) => {
            if (mode === "create") {
                createUser({
                    firstName,
                    lastName,
                    email,
                    employableFrom: employableFrom ? dateToDjango(employableFrom) : undefined,
                    employableTo: employableTo ? dateToDjango(employableTo) : undefined,
                    company: currentCompany!.id,
                    type,
                    sendInvitation,
                    weekCycle,
                    locations,
                    rolePreferences,
                    availabilities: availabilities.map(({ day, startTime, endTime }) => {
                        return { day, startSlot: startTime ? getSlot("start", timeToDate(startTime)) : undefined, endSlot: endTime ? getSlot("end", timeToDate(endTime)) : undefined }
                    }),
                })
                    .then(onSuccess)
                    .catch((error) => onFailure(error))
            } else if (mode === "update") {
                updateUser({
                    id: user!.id,
                    firstName,
                    lastName,
                    email,
                    employableFrom: employableFrom ? dateToDjango(employableFrom) : undefined,
                    employableTo: employableTo ? dateToDjango(employableTo) : undefined,
                    type,
                    weekCycle,
                    locations,
                    rolePreferences,
                    availabilities: availabilities.map(({ day, startTime, endTime }) => {
                        return { day, startSlot: startTime ? getSlot("start", timeToDate(startTime)) : undefined, endSlot: endTime ? getSlot("end", timeToDate(endTime)) : undefined }
                    }),
                })
                    .then(onSuccess)
                    .catch((error) => onFailure(error))
            } else if (mode === "update-my") {
                updateMy({
                    id: user!.id,
                    firstName,
                    lastName,
                })
                    .then(onSuccessAction)
                    .catch((error) => onFailure(error))
            }
        },
        [mode, user, currentCompany, onSuccess, onSuccessAction, onFailure]
    )

    const crumbTitle = useMemo(() => {
        return mode === "create" ? "Nieuw" : firstName + " " + lastName
    }, [mode, firstName, lastName])

    const pageTitle = useMemo(() => {
        if (mode === "update") {
            return firstName + " " + lastName
        } else if (mode === "create") {
            return "Nieuwe medewerker"
        } else {
            return "Mijn account"
        }
    }, [mode, firstName, lastName])

    useEffect(() => setActiveTab(mode !== "update-my" ? "Users" : ""), [setActiveTab, mode])

    return (
        <RegularPage id="UserEdit" breadCrumbs={mode !== "update-my" ? [{ title: "Medewerkers", link: "/medewerkers" }, { title: crumbTitle }] : undefined}>
            <div className="mb-4">
                <h1 className="h2">{pageTitle}</h1>
            </div>
            <UserTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <UserDetailsForm selectedTab={selectedTab} register={register} errors={errors} mode={mode} tabErrors={tabErrors} />
                <UserLocationsForm selectedTab={selectedTab} watch={watch} setValue={setValue} mode={mode} errors={errors} tabErrors={tabErrors} />
                <UserTimeForm selectedTab={selectedTab} register={register} watch={watch} setValue={setValue} clearErrors={clearErrors} mode={mode} errors={errors} tabErrors={tabErrors} />
                <UserRolesForm selectedTab={selectedTab} watch={watch} setValue={setValue} mode={mode} errors={errors} tabErrors={tabErrors} />
                <div className="mt-3">
                    <Button type="submit" className="me-2">
                        {mode === "create" ? "Maak" : "Opslaan"}
                    </Button>
                    {mode === "update" ? (
                        isActive ? (
                            <Button type="button" variant="danger" onClick={deactivateUser} className="ms-2">
                                Deactiveer account
                            </Button>
                        ) : (
                            <Button type="button" variant="link" onClick={inviteUser} className="ms-2">
                                Stuur uitnodiging
                            </Button>
                        )
                    ) : null}
                    <RootFeedback errors={errors} />
                    <FormSuccessMessage succeededId={succeededId} />
                </div>
            </Form>
        </RegularPage>
    )
}

export default UserEditPage
