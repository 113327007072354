import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import UserNameCell from "../../components/table/cells/UserNameCell"
import { useTabs } from "../../contexts/TabsContext"
import { useEmployableUsersForSelectedLocation, useIsAdmin, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { addMonths, dateFromDjango, isoPrint } from "../../helpers/DaysHelper"
import { hiringAvailabilityStatus, prettyPrintStatus } from "../../helpers/HiringAvailabilityHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadHiringAvailabilities } from "../../services/HiringAvailability"
import HiringAvailabilityType from "../../types/HiringAvailabilityType"

const HireAvailabilitiesAdminPage: FC = () => {
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const isAdmin = useIsAdmin()
    const users = useEmployableUsersForSelectedLocation()
    const resolveUserName = useResolveUserName(users)

    const columns = useMemo(
        (): ColumnDef<HiringAvailabilityType>[] => [
            {
                id: "user",
                header: "Medewerker",
                cell: ({ row }) => <UserNameCell userName={resolveUserName(row.original.user)} link={`/beschikbaarheid/${row.original.id}`} />,
            },
            {
                id: "date",
                header: "Datum",
                cell: ({ row }) => <span className="text-no-wrap">{isoPrint(dateFromDjango(row.original.date))}</span>,
            },
            {
                id: "status",
                header: "Beschikbaar",
                cell: ({ row }) => <span>{prettyPrintStatus(row.original.status)}</span>,
            },
            {
                id: "message",
                header: "Toelichting",
                cell: ({ row }) => <div>{row.original.message}</div>,
            },
        ],
        [resolveUserName]
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    useEffect(() => setActiveTab("HireAvailabilities"), [setActiveTab])

    return (
        <RegularPage id="HireAvailabilities">
            <div className="mb-4">
                <h1 className="h2">Beschikbaarheid</h1>
            </div>
            <Table
                id={`${location.id}_HiringAvailabilities`}
                columns={columns}
                buttons={[]}
                filters={[
                    locationFilter,
                    {
                        id: "date",
                        type: "daterange",
                        label: "Periode:",
                        initialValues: [
                            { key: "dateFrom", value: isoPrint(new Date()) },
                            { key: "dateTo", value: isoPrint(addMonths(new Date(), 1)) },
                        ],
                    },
                    {
                        id: "user",
                        type: isAdmin ? "picker" : "hidden",
                        label: "Medewerker:",
                        placeholder: "Naam...",
                        options: users.map((user) => {
                            return { key: user.id.toString(), title: `${user.firstName} ${user.lastName}` }
                        }),
                        initialValues: [{ key: "user", value: "" }],
                    },
                    {
                        id: "status",
                        type: "picker",
                        label: "Beschikbaar:",
                        placeholder: "Status...",
                        options: hiringAvailabilityStatus.map((status) => {
                            return { key: status.id, title: status.name }
                        }),
                        initialValues: [{ key: "status", value: "" }],
                    },
                ]}
                fetchData={loadHiringAvailabilities}
                subject="beschikbaarheden"
                minWidth="600px"
            />
        </RegularPage>
    )
}

export default HireAvailabilitiesAdminPage
