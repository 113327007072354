import { FC, useCallback, useContext, useState } from "react"
import { Button, Dropdown } from "react-bootstrap"
import { OccupationEditorContext } from "../../contexts/OccupationEditorContext"
import { storeOccupations } from "../../services/OccupationTemplate"
import FormErrorMessages from "../form/FormErrorMessages"
import FormSuccessMessage from "../form/FormSuccessMessage"
import Loader from "../Loader"
import CopyOccupationsDayModal from "./CopyOccupationsDayModal"
import OccupationEditorDaySelector from "./OccupationEditorDaySelector"
import OccupationsEditor from "./OccupationsEditor"
import OccupationsEditorHead from "./OccupationsEditorHead"

interface Props {
    mode: "DAYS" | "DATERANGE"
}
const OccupationEditor: FC<Props> = ({ mode }) => {
    const { editorState, reloadEditorFromOccupationTemplate } = useContext(OccupationEditorContext)

    const [succeededId, setSucceededId] = useState(0)
    const [formErrors, setFormErrors] = useState<string[]>([])

    const [showCopyModal, setShowCopyModal] = useState(false)

    const openCopyModal = useCallback(() => setShowCopyModal(true), [setShowCopyModal])
    const closeCopyModal = useCallback(() => setShowCopyModal(false), [setShowCopyModal])

    const onSuccess = useCallback(() => {
        setSucceededId(succeededId + 1)
        setFormErrors([])
        reloadEditorFromOccupationTemplate()
    }, [setSucceededId, succeededId, setFormErrors, reloadEditorFromOccupationTemplate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setSucceededId(0)
            setFormErrors(data.nonFieldErrors || ["Er is iets misgegaan"])
        },
        [setSucceededId, setFormErrors]
    )

    const save = useCallback(() => {
        storeOccupations(editorState!.occupationTemplate!.id, editorState!.mergedSchedule).then(onSuccess).catch(onFailure)
    }, [editorState?.occupationTemplate, editorState?.mergedSchedule, onSuccess, onFailure])

    if (!editorState) {
        return <Loader />
    }

    return (
        <div>
            <OccupationsEditorHead />

            <div className="flex-bar mb-3">
                <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}>
                    <OccupationEditorDaySelector />
                </div>
                <div className="filter-panel">
                    <Dropdown className="d-inline-block">
                        <Dropdown.Toggle variant="link" id="action-dropdown-toggle">
                            Acties
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={openCopyModal}>Dag kopiëren</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <CopyOccupationsDayModal visible={showCopyModal} close={closeCopyModal} />

            <OccupationsEditor />

            <Button type="button" onClick={save} data-cy="saveOccupations">
                Opslaan
            </Button>
            <FormErrorMessages errors={formErrors} />
            <FormSuccessMessage succeededId={succeededId} />
        </div>
    )
}

export default OccupationEditor
