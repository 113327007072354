import { FC, useMemo } from "react"
import { UseFormClearErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useLocations } from "../../../contexts/UserSettingsContext"
import { getDayName, getEnabledDays, getStartAndEndSlot, isEnabled } from "../../../helpers/DaysHelper"
import { UserEditInputs } from "../../../pages/users/UserEditPage"
import UserTimeInput from "./UserTimeInput"

interface Props {
    week: number
    day: number
    disabled: boolean
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    clearErrors: UseFormClearErrors<UserEditInputs>
}

const UserTimeTableRow: FC<Props> = ({ week, day, disabled, watch, setValue, clearErrors }) => {
    const locations = useLocations()
    const [startSlot, endSlot] = useMemo(() => getStartAndEndSlot(locations), [locations])
    const enabledDays = useMemo(() => getEnabledDays(locations), [locations])

    const dayIndex = useMemo(() => {
        return day + 7 * week
    }, [week, day])

    if (!isEnabled(enabledDays, week, day, true)) {
        return null
    }

    return (
        <tr>
            <td className="time-table-cell text-bold pe-3">{getDayName(day)}</td>
            <td className="time-table-cell pe-4">
                <UserTimeInput day={dayIndex} startSlot={startSlot} endSlot={endSlot} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
            </td>
        </tr>
    )
}

export default UserTimeTableRow
