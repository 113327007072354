import { FC, useCallback, useEffect, useState } from "react"
import { Badge, Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import FormSuccessMessage from "../../components/form/FormSuccessMessage"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useCurrentUser } from "../../contexts/UserSettingsContext"
import { setOptionalError } from "../../helpers/FormHelper"
import { editPassword, loadDetailedUser } from "../../services/User"

interface Inputs {
    oldPassword: string
    password1: string
    password2: string
}

const MyPasswordPage: FC = () => {
    const { setActiveTab } = useTabs()
    const currentUser = useCurrentUser()
    const [hasUsablePassword, setHasUsablePassword] = useState(true)
    const [succeededId, setSucceededId] = useState(0)
    const {
        register,
        reset,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const loadUser = useCallback(
        (id: number) => {
            loadDetailedUser(id).then((user) => setHasUsablePassword(user.hasUsablePassword))
        },
        [setHasUsablePassword]
    )

    const onSuccess = useCallback(() => {
        reset()
        setSucceededId(succeededId + 1)
    }, [reset, setSucceededId, succeededId])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "oldPassword", data.old_password)
            setOptionalError(setError, "password1", data.new_password1)
            setOptionalError(setError, "password2", data.new_password2)
            setOptionalError(setError, "root", data.__all__)
            setSucceededId(0)
        },
        [setError, setSucceededId]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ oldPassword, password1, password2 }) => {
            editPassword(oldPassword, password1, password2).then(onSuccess).catch(onFailure)
        },
        [onSuccess, onFailure]
    )

    useEffect(() => setActiveTab("Account"), [setActiveTab])

    useEffect(() => loadUser(currentUser!.id), [loadUser, currentUser])

    return (
        <RegularPage id="MyPassword">
            <div className="mb-4">
                <h1 className="h2">Wachtwoord wijzigen</h1>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="mb-4">
                {!hasUsablePassword ? (
                    <Badge bg="info-light" text="dark" className="mb-4">
                        <i className="fas fa-exclamation-circle me-2"></i>
                        Account heeft geen huidig wachtwoord, neem contact op met <a href="mailto:info@llmsolutions.nl">info@llmsolutions.nl</a> om een wachtwoord in te stellen.
                    </Badge>
                ) : null}
                <Form.Group className="mb-3">
                    <Form.Label>Huidig wachtwoord</Form.Label>
                    <Form.Control type="password" {...register("oldPassword")} isInvalid={!!errors.oldPassword} data-cy="oldPassword" disabled={!hasUsablePassword} />
                    <Form.Control.Feedback type="invalid" data-cy="oldPassword_errors">
                        {errors.oldPassword?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nieuw wachtwoord</Form.Label>
                    <Form.Control type="password" {...register("password1")} isInvalid={!!errors.password1} data-cy="password1" disabled={!hasUsablePassword} />
                    <Form.Control.Feedback type="invalid" data-cy="password1_errors">
                        {errors.password1?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nieuw wachtwoord nogmaals</Form.Label>
                    <Form.Control type="password" {...register("password2")} isInvalid={!!errors.password2} data-cy="password2" disabled={!hasUsablePassword} />
                    <Form.Control.Feedback type="invalid" data-cy="password2_errors">
                        {errors.password2?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={!hasUsablePassword}>
                        Wachtwoord opslaan
                    </Button>
                </Form.Group>
                <Form.Group>
                    <FormSuccessMessage succeededId={succeededId} />
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </RegularPage>
    )
}

export default MyPasswordPage
