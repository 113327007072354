import { FC } from "react"
import ErrorMessages from "../form/ErrorMessages"
import { UserTab } from "./UserTabs"

interface Props {
    tabErrors: UserTab[]
    except: UserTab
}

const UserTabErrors: FC<Props> = ({ tabErrors, except }) => {
    const errors = tabErrors.filter((e) => e !== except).map((t) => `Er is iets fout gegaan op het tabblad ${t}`)
    if (errors.length === 0) {
        return null
    }
    return <ErrorMessages forId="form" errors={errors} />
}

export default UserTabErrors
