import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getLocationFilter } from "../../helpers/LocationHelper"
import { loadRoleGroups } from "../../services/RoleGroup"
import RoleGroupType from "../../types/RoleGroupType"

const RoleGroupsPage: FC = () => {
    const location = useSelectedLocation()
    const { setActiveTab } = useTabs()

    useEffect(() => setActiveTab("Roles"), [setActiveTab])

    const columns = useMemo(
        (): ColumnDef<RoleGroupType>[] => [
            {
                id: "name",
                header: "Naam",
                cell: ({ row }) => <Link to={`/taakgroepen/${row.original.id}`}>{row.original.name}</Link>,
            },
            {
                id: "hasOccupationTarget",
                header: "Basisbezetting",
                cell: ({ row }) => {
                    if (row.original.hasOccupationTarget) {
                        return <i className="fas fa-check font-success"></i>
                    } else {
                        return null
                    }
                },
            },
        ],
        []
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="RoleGroups" breadCrumbs={[{ title: "Taken", link: "/taken" }, { title: "Taakgroepen" }]}>
            <div className="mb-4">
                <h1 className="h2">Taakgroepen</h1>
            </div>
            <Table
                id={`${location.id}_RoleGroups`}
                columns={columns}
                buttons={[{ name: "Maak nieuwe taakgroep", link: "/taakgroepen/nieuw" }]}
                fetchData={loadRoleGroups}
                filters={[locationFilter]}
                subject="taakgroepen"
                minWidth="200px"
            />
        </RegularPage>
    )
}

export default RoleGroupsPage
