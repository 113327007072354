import { FC, useCallback, useContext, useMemo, useState } from "react"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { getOccupationData, getOccupationIndicators } from "../../../helpers/OccupationHelper"
import { ScheduleItem } from "../../../helpers/ScheduleMapHelper"
import OccupationTimeSlot from "./OccupationTimeSlot"
import OccupationTimeSlotPopover from "./OccupationTimeSlotPopover"
import RolePlanningPopover from "./rolePlanning/RolePlanningPopover"

interface Props {
    selectedSlot: number | undefined
    setSelectedSlot: (selectedSlot: number | undefined) => void
    planningRole: number | undefined
    setPlanningRole: (planningRole: number | undefined) => void
    daySchedule: Map<string, ScheduleItem>
}

const OccupationTimeSlots: FC<Props> = ({ selectedSlot, setSelectedSlot, planningRole, setPlanningRole, daySchedule }) => {
    const { applyMutation } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()
    const { location, roles, nestedRoles, occupations, enabledRoles } = editorState
    const { startSlot, endSlot } = location

    const [popoverFaded, setPopoverFaded] = useState(false)

    const occupationData = useMemo(
        () => getOccupationData(nestedRoles, enabledRoles, startSlot, endSlot, daySchedule, occupations),
        [nestedRoles, enabledRoles, startSlot, endSlot, daySchedule, occupations]
    )

    const occupationIndicators = useMemo(() => getOccupationIndicators(startSlot, endSlot, occupationData), [startSlot, endSlot, occupationData])

    const onDetailsClosed = useCallback(() => setSelectedSlot(undefined), [setSelectedSlot])

    const slotItems = useMemo(() => {
        const items: number[] = []
        for (let slot = startSlot; slot <= endSlot; slot++) {
            items.push(slot)
        }
        return items
    }, [startSlot, endSlot])

    const applyProposalOption = useCallback(
        (user: number, startSlot: number, endSlot: number, role: number | null) => {
            const cellFrom = startSlot - location.startSlot
            const cellTo = endSlot - location.startSlot
            applyMutation(user, cellFrom, cellTo, role)
            setSelectedSlot(undefined)
            setPlanningRole(undefined)
        },
        [location, applyMutation, setSelectedSlot, setPlanningRole]
    )

    const togglePopoverFaded = useCallback(() => setPopoverFaded(!popoverFaded), [setPopoverFaded, popoverFaded])

    return (
        <div className="editor-occupations">
            <div className="editor-cell-title" />
            {slotItems.map((slot) => (
                <OccupationTimeSlot key={slot} slot={slot} startSlot={startSlot} selectedSlot={selectedSlot} occupationIndicators={occupationIndicators} setSelectedSlot={setSelectedSlot} />
            ))}
            {planningRole === undefined ? (
                <OccupationTimeSlotPopover
                    key={selectedSlot}
                    selectedSlot={selectedSlot}
                    occupationData={occupationData}
                    onClosePopover={onDetailsClosed}
                    setPlanningRole={setPlanningRole}
                    popoverFaded={popoverFaded}
                    togglePopoverFaded={togglePopoverFaded}
                />
            ) : (
                <RolePlanningPopover
                    roleId={planningRole}
                    selectedSlot={selectedSlot}
                    startSlot={startSlot}
                    endSlot={endSlot}
                    roles={roles}
                    onClosePopover={onDetailsClosed}
                    applyProposalOption={applyProposalOption}
                    setPlanningRole={setPlanningRole}
                    popoverFaded={popoverFaded}
                    togglePopoverFaded={togglePopoverFaded}
                />
            )}
        </div>
    )
}

export default OccupationTimeSlots
