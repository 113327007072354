import { FC } from "react"
import UserTabComponent from "./UserTabComponent"

export type UserTab = "Profiel" | "Locaties" | "Werktijden" | "Taken"

export const userTabs: UserTab[] = ["Profiel", "Locaties", "Werktijden", "Taken"]

interface UserTabsProps {
    selectedTab: UserTab
    setSelectedTab: (userTab: UserTab) => void
}

const UserTabs: FC<UserTabsProps> = ({ selectedTab, setSelectedTab }) => {
    return (
        <div className="overflow-auto mb-3">
            <ul className="selectable-segment me-3">
                {userTabs.map((t) => (
                    <UserTabComponent key={t} tab={t} active={t === selectedTab} setSelectedTab={setSelectedTab} />
                ))}
            </ul>
        </div>
    )
}

export default UserTabs
