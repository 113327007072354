import { FC } from "react"
import { EnvironmentGenerationStepStatus } from "../../types/EnvironmentGenerationType"

interface EnvinromentGenerationStepProps {
    index: number
    title: string
    status: EnvironmentGenerationStepStatus
}

const getColor = (status: EnvironmentGenerationStepStatus) => {
    switch (status) {
        case EnvironmentGenerationStepStatus.FAILED:
            return "red"
        case EnvironmentGenerationStepStatus.SUCCEEDED:
            return "green"
        default:
            return undefined
    }
}

const getIconClass = (status: EnvironmentGenerationStepStatus) => {
    switch (status) {
        case EnvironmentGenerationStepStatus.TODO:
            return "fa-solid fa-circle-notch"
        case EnvironmentGenerationStepStatus.EXECUTING:
            return "fa-solid fa-circle-notch fa-spin"
        case EnvironmentGenerationStepStatus.FAILED:
            return "fa-solid fa-circle-xmark fa-beat"
        case EnvironmentGenerationStepStatus.SUCCEEDED:
            return "fa-solid fa-circle-check fa-beat"
        default:
            return ""
    }
}

const EnvinromentGenerationStep: FC<EnvinromentGenerationStepProps> = ({ index, title, status }) => {
    return (
        <div className="d-flex">
            <span className="h4 me-2">
                <i
                    className={getIconClass(status)}
                    style={{
                        animationIterationCount: status === EnvironmentGenerationStepStatus.EXECUTING ? undefined : 1,
                        animationDuration: "500ms",
                        animationDelay: `${index * 100}ms`,
                        color: getColor(status),
                    }}
                ></i>
            </span>
            {title}
        </div>
    )
}

export default EnvinromentGenerationStep
