import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { useOccupationEditorState } from "../../contexts/OccupationEditorContext"

const OccupationsEditorHead = () => {
    const editorState = useOccupationEditorState()

    const { id, name } = useMemo(() => {
        return {
            id: editorState.occupationTemplate?.id ?? 0,
            name: editorState.occupationTemplate?.name ?? "",
        }
    }, [editorState.occupationTemplate])

    return (
        <div className="mb-4">
            <h1 className="h2 d-inline-block">
                {name}
                <span className="ms-3 title-button">
                    <Link to={`/basisbezettingen/${id}/details`} data-cy="toDetails">
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                </span>
            </h1>
        </div>
    )
}

export default OccupationsEditorHead
