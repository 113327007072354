import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { nl } from "date-fns/locale/nl"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from "react-router-dom"
import { StatusProvider } from "./contexts/StatusContext"
import { TabsProvider } from "./contexts/TabsContext"
import { UserSettingsProvider } from "./contexts/UserSettingsContext"
import MainLayout from "./layouts/MainLayout"
import "./sass/react-datepicker.scss"
import "./sass/rooster.scss"

registerLocale("nl", nl)
setDefaultLocale("nl")

const queryClient = new QueryClient()

function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <TabsProvider>
                    <UserSettingsProvider>
                        <StatusProvider>
                            <MainLayout />
                        </StatusProvider>
                    </UserSettingsProvider>
                </TabsProvider>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

export default App
