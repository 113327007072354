import { faCaretDown, faCaretUp, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import RolesLegend from "../../components/editor/RolesLegend"
import FormColorSelect from "../../components/form/FormColorSelect"
import InfoPopover from "../../components/InfoPopover"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useRoleGroupsForSelectedLocation, UserSettingsContext, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { ROLE_TYPE } from "../../helpers/Constants"
import { setOptionalError } from "../../helpers/FormHelper"
import { createRole, loadRole, removeRole, updateRole } from "../../services/Role"

const rolePriorityOptions = [
    { id: 0, name: "Geen prioriteit" },
    { id: 1, name: "Neutraal" },
    { id: 2, name: "Hoog" },
]

interface Props {
    mode: "Create" | "Update"
}

interface Inputs {
    name: string
    type: ROLE_TYPE
    color: number | undefined
    roleGroup: number | undefined
    hasOccupationTarget: boolean
    isAbsence: boolean
    priority: number
    isDefaultPreference: boolean
}

const EditRolePage: FC<Props> = ({ mode }) => {
    const location = useSelectedLocation()
    const roleGroups = useRoleGroupsForSelectedLocation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)

    const {
        register,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            name: "",
            type: "regular",
            color: undefined,
            roleGroup: undefined,
            hasOccupationTarget: false,
            isAbsence: false,
            priority: rolePriorityOptions[1].id,
            isDefaultPreference: true,
        },
    })

    const [showLegend, setShowLegend] = useState(false)
    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const roleTypeOptions = useMemo(() => {
        const options: { id: ROLE_TYPE; name: string }[] = [
            { id: "regular", name: "Standaard" },
            { id: "leave", name: "Verlof" },
            { id: "sick", name: "Ziek" },
            { id: "break", name: "Pauze" },
        ]
        return options
    }, [])

    const { data: role } = useQuery(["Role", id], loadRole(id), { enabled: mode !== "Create" })

    useEffect(() => {
        if (!role) {
            return
        }
        setValue("name", role.name)
        setValue("type", role.type)
        setValue("color", role.color)
        setValue("roleGroup", role.roleGroup)
        setValue("hasOccupationTarget", role.hasOccupationTarget)
        setValue("isAbsence", role.isAbsence)
        setValue("priority", role.priority)
        setValue("isDefaultPreference", role.isDefaultPreference)
    }, [role])

    const onSuccess = useCallback(() => {
        reloadUserSettings()
        navigate("/taken")
    }, [reloadUserSettings, navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "name", data.name)
            setOptionalError(setError, "type", data.type)
            setOptionalError(setError, "color", data.color)
            setOptionalError(setError, "roleGroup", data.roleGroup)
            setOptionalError(setError, "hasOccupationTarget", data.hasOccupationTarget)
            setOptionalError(setError, "isAbsence", data.isAbsence)
            setOptionalError(setError, "priority", data.priority)
            setOptionalError(setError, "isDefaultPreference", data.isDefaultPreference)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ name, type, color, roleGroup, hasOccupationTarget, isAbsence, priority, isDefaultPreference }) => {
            if (mode === "Create") {
                createRole({
                    location: location.id,
                    name,
                    type,
                    color,
                    roleGroup,
                    hasOccupationTarget,
                    isAbsence,
                    priority,
                    isDefaultPreference,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateRole({
                    id,
                    name,
                    type,
                    color,
                    roleGroup,
                    hasOccupationTarget,
                    isAbsence,
                    priority,
                    isDefaultPreference,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            }
        },
        [id, location, onSuccess, onFailure]
    )

    const onDeleteRole = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        removeRole(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, id, onSuccess, onFailure])

    const toggleLegend = useCallback(() => setShowLegend(!showLegend), [setShowLegend, showLegend])

    useEffect(() => setActiveTab("Roles"), [setActiveTab])

    const name = watch("name")
    const hasOccupationTarget = watch("hasOccupationTarget")

    const crumbTitle = useMemo(() => (mode === "Create" ? "Nieuw" : name), [name])
    const pageTitle = useMemo(() => (mode === "Create" ? "Nieuwe taak" : name), [name])

    const color = watch("color")
    const setColor = useCallback(
        (color: number) => {
            setValue("color", color)
            clearErrors("color")
        },
        [setValue]
    )

    return (
        <RegularPage id="EditRole" breadCrumbs={[{ title: "Taken", link: "/taken" }, { title: crumbTitle }]}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <h1 className="h2">{pageTitle}</h1>
                </div>
                <Form.Group className="mb-3">
                    <Form.Label>Naam</Form.Label>
                    <Form.Control size="lg" type="text" {...register("name")} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Select size="lg" {...register("type")} isInvalid={!!errors.type}>
                        {roleTypeOptions.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Kleur</Form.Label>
                    <FormColorSelect selectedColor={color} setSelectedColor={setColor} />
                    <Form.Control type="hidden" isInvalid={!!errors.color} />
                    <Form.Control.Feedback type="invalid">{errors.color?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Taakgroep</Form.Label>
                    <InfoPopover
                        id="roleGroup"
                        body="Indien verschillende taken in dezelfde categorie vallen kun je een taakgroep toewijzen. Bv; telefoon Den Haag, telefoon Goes en telefoon Gouda vallen allemaal onder de categorie telefoon. Bij de bezetting wordt nu gekeken naar het totaal aantal op de telefoon in plaats van per locatie aangezien het in theorie niet uit zou maken waar de telefoon wordt gehanteerd."
                    />
                    <Form.Select size="lg" {...register("roleGroup")} isInvalid={!!errors.roleGroup}>
                        <option value="">Kies...</option>
                        {roleGroups.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.roleGroup?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Prioriteit bij automatisch invullen rooster</Form.Label>
                    <InfoPopover
                        id="priority"
                        body="Dit bepaalt de prioriteit van een taak bij het automatisch genereren van een rooster. Taken met een hogere prioriteit worden eerder ingeroosterd en dus volledig bemand tegenover taken met een lagere prioriteit."
                    />
                    <Form.Select size="lg" {...register("priority")} isInvalid={!!errors.priority}>
                        {rolePriorityOptions.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.priority?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Standaard inschakelen voor nieuwe medewerkers</Form.Label>
                    <InfoPopover id="isDefaultPreference" body="Indien aangevinkt wordt deze taak automatisch ingeschakeld op het medewerkersprofiel van nieuwe medewerkers." />
                    <Form.Check {...register("isDefaultPreference")} isInvalid={!!errors.isDefaultPreference} />
                    <Form.Control.Feedback type="invalid">{errors.isDefaultPreference?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Basisbezetting instelbaar</Form.Label>
                    <InfoPopover
                        id="hasOccupationTarget"
                        body="Indien aangevinkt verschijnt deze taak in de basisbezetting. Dit houdt in dat je aan kunt geven hoeveel werknemers je verwacht per dag en per uur."
                    />
                    <Form.Check {...register("hasOccupationTarget")} isInvalid={!!errors.hasOccupationTarget} />
                    <Form.Control.Feedback type="invalid">{errors.hasOccupationTarget?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Uitsluiten van totaaltelling</Form.Label>
                    <InfoPopover
                        id="isAbsence"
                        body="Indien aangevinkt worden de werknemers die deze taak hebben toegewezen niet meegeteld in het totaal aantal aanwezigen binnen het rooster. Dit kan bijvoorbeeld gebruikt worden voor vakanties."
                    />
                    <Form.Check {...register("isAbsence")} isInvalid={!!errors.isAbsence} />
                    <Form.Control.Feedback type="invalid">{errors.isAbsence?.message}</Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex align-items-center">
                    <Button type="submit" className="me-2">
                        {mode === "Create" ? "Maak" : "Opslaan"}
                    </Button>
                    {mode === "Update" ? (
                        <Button type="button" onClick={onDeleteRole} variant="danger">
                            Verwijder
                        </Button>
                    ) : null}
                    {deletionWarningVisible ? (
                        <div className="ms-1 mt-2">
                            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                            <span className="me-1">Weet je zeker dat je deze verlofaanvraag wil verwijderen?</span>
                            <Button variant="link" type="button" onClick={onDeleteRole}>
                                Ja, verwijder
                            </Button>
                        </div>
                    ) : null}
                </div>
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Button onClick={toggleLegend} variant="link" className="mt-3">
                    {showLegend ? "Verberg huidige taken" : "Toon huidige taken"}
                    <FontAwesomeIcon icon={showLegend ? faCaretUp : faCaretDown} className="ms-1" />
                </Button>
                {showLegend ? (
                    <div className="mt-3">
                        <RolesLegend />
                    </div>
                ) : null}
            </Form>
        </RegularPage>
    )
}

export default EditRolePage
