import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import { useQuery } from "react-query"
import { ScheduleEditorContext } from "../../../../contexts/ScheduleEditorContext"
import { useColors } from "../../../../contexts/UserSettingsContext"
import { getCSSColor, getCSSColor3 } from "../../../../helpers/ColorHelper"
import { getPopoverStyle } from "../../../../helpers/editor/OccupationDetailsHelper"
import { refetchInterval } from "../../../../helpers/QueryHelper"
import { loadTask, TaskShiftMutationProposalResult, TaskShiftTemplateMutationProposalResult, TaskType } from "../../../../services/Task"
import RoleType from "../../../../types/RoleType"
import Loader from "../../../Loader"
import RolePlanningOptions from "./RolePlanningOptions"

interface RolePlanningPopoverProps {
    roles: RoleType[]
    roleId: number
    selectedSlot?: number
    startSlot: number
    endSlot: number
    popoverFaded: boolean
    applyProposalOption: (user: number, startSlot: number, endSlot: number, role: number | null) => void
    setPlanningRole: (planningRole: number | undefined) => void
    togglePopoverFaded: () => void
    onClosePopover: () => void
}

const RolePlanningPopover: FC<RolePlanningPopoverProps> = ({
    roles,
    roleId,
    selectedSlot,
    startSlot,
    endSlot,
    popoverFaded,
    applyProposalOption,
    setPlanningRole,
    togglePopoverFaded,
    onClosePopover,
}) => {
    const colors = useColors()
    const { makeProposal } = useContext(ScheduleEditorContext)
    const [initialTask, setInitialTask] = useState<TaskType<TaskShiftMutationProposalResult | TaskShiftTemplateMutationProposalResult> | null>(null)

    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<TaskShiftMutationProposalResult | TaskShiftTemplateMutationProposalResult>>(
        ["Task", initialTask?.id],
        () => loadTask<TaskShiftMutationProposalResult>(initialTask!.id),
        {
            enabled: !!initialTask,
            refetchInterval: refetchInterval(fetchCount),
            keepPreviousData: false,
        }
    )

    useEffect(() => {
        makeProposal(selectedSlot!, roleId).then((response) => setInitialTask(response))
    }, [makeProposal, selectedSlot, roleId, setInitialTask])

    const resetPlanningRole = useCallback(() => setPlanningRole(undefined), [setPlanningRole])

    const role = useMemo(() => roles.find((r) => r.id === roleId)!, [roles, roleId])
    const roleColor = useMemo(() => colors.get(role.color)!, [colors, role])

    const popoverStyle: CSSProperties = useMemo(() => getPopoverStyle(selectedSlot, startSlot, endSlot, popoverFaded), [selectedSlot, startSlot, endSlot, popoverFaded])

    if (roleId === null || selectedSlot === null) {
        return null
    }

    return (
        <div className="rp-tooltip role-planning-popover" style={popoverStyle}>
            <div className="occupation-details-header">
                <button className="btn btn-link" onClick={resetPlanningRole}>
                    <i className="fas fa-chevron-left"></i>
                </button>
                <div className="occupation-details-popover-title">
                    <span className="text-bold popover-title">Taak toewijzen</span>
                    <span className="role-badge text-bold ms-3" style={{ backgroundColor: getCSSColor3(roleColor, 0.2) }}>
                        <i className="fas fa-circle me-2" style={{ color: getCSSColor(roleColor) }}></i>
                        {role?.name}
                    </span>
                </div>
                <Button type="button" variant="link" onClick={togglePopoverFaded} className="px-2">
                    <FontAwesomeIcon icon={popoverFaded ? faEye : faEyeSlash} />
                </Button>
                <Button type="button" variant="link" onClick={onClosePopover} className="px-2">
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </div>
            {!task?.done ? (
                <div className="p-4">
                    <Loader />
                </div>
            ) : null}
            <RolePlanningOptions task={task} applyProposalOption={applyProposalOption} />
        </div>
    )
}

export default RolePlanningPopover
