import GeneratedMutationType from "../types/GeneratedMutationType"
import LocalityMutationType from "../types/LocalityMutationType"
import LocalityType, { LocalityOptionOrInherit, StaticLocalityType } from "../types/LocalityType"
import LocationType from "../types/LocationType"
import ShiftMutationType from "../types/ShiftMutationType"
import ShiftMutationsPlanType from "../types/ShiftMutationsPlanType"
import ShiftTemplateMutationsPlanType from "../types/ShiftTemplateMutationsPlanType"
import ShiftType, { StaticShiftType } from "../types/ShiftType"
import { UserDetailedType } from "../types/UserType"
import { getApplicableAvailabilities } from "./AvailabilityHelper"
import { addDays, dateFromDjango, getDayId, getSlot } from "./DaysHelper"
import { ensureMapForKey } from "./MapHelper"

export function mergeShiftMutation(mutations: ScheduleMap, newMutation: StaticShift, locationId: string, dayId: string, userId: string): ScheduleMap {
    const newMutationsForUserAndDay = mergeMutation(getShifts(mutations, locationId, dayId, userId), newMutation)
    const map: ScheduleMap = new Map(mutations)
    ensureLocationAndDayAndUser(map, locationId, dayId, userId, "INHERIT")
    map.get(locationId)!.get(dayId)!.get(userId)!.shifts = newMutationsForUserAndDay
    return map
}

export function mergeLocalityMutation(mutations: ScheduleMap, type: LocalityOptionOrInherit, locationId: string, dayId: string, userId: string): ScheduleMap {
    const map: ScheduleMap = new Map(mutations)
    ensureLocationAndDayAndUser(map, locationId, dayId, userId, "INHERIT")
    map.get(locationId)!.get(dayId)!.get(userId)!.locality = type
    return map
}

export function mergeMaps(map1: ScheduleMap, map2: ScheduleMap, filterNullRole: boolean): ScheduleMap {
    const map = new Map(map1)
    map2.forEach((locationMap2, locationId) => {
        locationMap2.forEach((dayMap2, dayId) => {
            dayMap2.forEach((scheduleItem2, userId) => {
                ensureLocationAndDayAndUser(map, locationId, dayId, userId, "INHERIT")
                const dayMap1 = map.get(locationId)!.get(dayId)!
                const mergedList = mergeLists(dayMap1.get(userId)!.shifts, scheduleItem2.shifts)
                const scheduleItem = dayMap1.get(userId)!
                scheduleItem.shifts = filterNullRole ? mergedList.filter(roleNotNull) : mergedList
                scheduleItem.locality = scheduleItem2.locality === "INHERIT" ? scheduleItem.locality : scheduleItem2.locality
            })
        })
    })
    return map
}

export function mergeLists(list1: StaticShift[], list2: StaticShift[]): StaticShift[] {
    let list = [...list1]
    for (const shift of list2) {
        list = mergeMutation(list, shift)
    }
    return list
}

export function mergeMutation(shifts: StaticShift[], shiftMutation: StaticShift) {
    const newShifts = []

    for (let shift of shifts) {
        // If mutations overlaps
        if (shift.startSlot <= shiftMutation.endSlot && shift.endSlot >= shiftMutation.startSlot) {
            // If shift has a remainder before mutation, add new shift
            if (shift.startSlot < shiftMutation.startSlot) {
                newShifts.push({
                    startSlot: shift.startSlot,
                    endSlot: shiftMutation.startSlot - 1,
                    role: shift.role,
                })
            }
            // If shift has a remainder after mutation, add new shift
            if (shift.endSlot > shiftMutation.endSlot) {
                newShifts.push({
                    startSlot: shiftMutation.endSlot + 1,
                    endSlot: shift.endSlot,
                    role: shift.role,
                })
            }
        } else {
            newShifts.push(shift)
        }
    }
    // Add new shift for mutation
    newShifts.push({
        startSlot: shiftMutation.startSlot,
        endSlot: shiftMutation.endSlot,
        role: shiftMutation.role,
    })

    return mergeShifts(newShifts)
}

export function mergeShifts(shifts: StaticShift[]) {
    const newShifts = [...shifts]

    newShifts.sort((a, b) => (a.startSlot < b.startSlot ? -1 : 1))
    let i = 1
    while (i < newShifts.length) {
        // If shifts are right after eachother and have the same role
        if (newShifts[i].startSlot <= newShifts[i - 1].endSlot + 1 && newShifts[i].role === newShifts[i - 1].role) {
            // Set endslot of first shift to max endslot
            newShifts[i - 1].endSlot = newShifts[i].endSlot
            // And remove the second shift
            newShifts.splice(i, 1)
        } else {
            i++
        }
    }

    return newShifts
}

export function normalizeMutations(mutations: ScheduleMap) {
    mutations.forEach((locationMap) => {
        locationMap.forEach((dayMap) => {
            dayMap.forEach((scheduleItem, userId) => {
                scheduleItem.shifts = mergeShifts(scheduleItem.shifts)
            })
        })
    })
}

// ----------- Dashboard -----------

// Map containing: { dayId: { locationId: { shifts: staticSift[], locality: LocalityOptionOrInherit } }
export type DashboardScheduleMap = Map<string, Map<string, ScheduleItem>>

export function getScheduleMap(shifts: StaticShiftType[], shiftMutations: ShiftMutationType[], localities: StaticLocalityType[], localityMutations: LocalityMutationType[]): ScheduleMap {
    const shiftMap = toScheduleMapDate(shifts, localities, "UNKNOWN")
    const mutationMap = toScheduleMapFromMutations(shiftMutations, localityMutations, (mutation: ShiftMutationType | LocalityMutationType) => mutation.date)

    return mergeMaps(shiftMap, mutationMap, true)
}

export function roleNotNull(shift: StaticShift) {
    return !!shift.role
}

export function toDashboardScheduleMap(shifts: ScheduleMap, startDate: Date, endDate: Date): DashboardScheduleMap {
    const map: DashboardScheduleMap = new Map()

    shifts.forEach((dateMap, location) => {
        const locationId = location.toString()
        dateMap.forEach((userMap, dayId) => {
            const date = dateFromDjango(dayId)
            if (date >= startDate && date <= endDate) {
                const scheduleItem: ScheduleItem = userMap.values().next().value
                const shifts = [...scheduleItem.shifts]
                if (shifts.length > 0) {
                    shifts.sort((a, b) => (a.startSlot > b.startSlot ? 1 : -1))
                    ensureMapForKey(map, dayId)
                    map.get(dayId)!.set(locationId, { shifts: shifts, locality: scheduleItem.locality })
                }
            }
        })
    })
    return map
}

// ----------- Absence planner -----------

export const makeAbsenceMutations = (user: UserDetailedType, location: LocationType, dateFrom: Date, timeFrom: Date | undefined, dateTo: Date, timeTo: Date | undefined, roleId: number) => {
    const map: ScheduleMap = new Map()
    const locationKey = location.id.toString()
    const userKey = user.id.toString()

    for (let date = dateFrom; date <= (dateTo ? dateTo : dateFrom); date = addDays(date, 1)) {
        const dayId = getDayId(date)
        ensureLocationAndDayAndUser(map, locationKey, dayId, userKey, "INHERIT")

        const availabilities = getApplicableAvailabilities(user, date)
        if (availabilities.length === 0) {
            continue
        }

        let start = Math.min.apply(
            Math,
            availabilities.map((a) => a.startSlot)
        )
        let end = Math.max.apply(
            Math,
            availabilities.map((a) => a.endSlot)
        )

        if (!!start && !!timeFrom && date.getTime() === dateFrom.getTime()) {
            start = Math.max(start, getSlot("start", timeFrom))
        }
        if (!!end && !!timeTo && date.getTime() === dateTo.getTime()) {
            end = Math.min(end, getSlot("end", timeTo))
        }

        if (!!start && !!end) {
            const item = {
                startSlot: start,
                endSlot: end,
                role: roleId,
            }
            map.get(locationKey)!.get(dayId)!.get(userKey)!.shifts.push(item)
        }
    }

    return map
}

// ----------- Editor cells -----------

export const getCellValues = (startSlot: number, endSlot: number, shifts: StaticShift[]) => {
    const values: number[] = []
    const sortedShifts = shifts.sort((a, b) => (a.startSlot > b.startSlot ? 1 : -1))
    const shiftIterator = sortedShifts[Symbol.iterator]()
    let currentShift: StaticShift | undefined
    for (let i = startSlot; i <= endSlot; i++) {
        if (!currentShift || i > currentShift.endSlot) {
            currentShift = shiftIterator.next().value
        }
        if (currentShift && currentShift.startSlot <= i && currentShift.endSlot >= i && currentShift.role) {
            values.push(currentShift.role)
        } else {
            values.push(0)
        }
    }
    return values
}

// ----------- Utils -----------

export type ScheduleItem = { shifts: StaticShift[]; locality: LocalityOptionOrInherit }
export type ScheduleMap = Map<string, Map<string, Map<string, ScheduleItem>>>

export interface StaticShift {
    startSlot: number
    endSlot: number
    role: number | null
}

export interface StaticLocality {
    option: number | "UNKNOWN" | "INHERIT"
}

const shiftToStaticShift = (shift: ShiftType | StaticShiftType | ShiftMutationType | GeneratedMutationType): StaticShift => {
    return {
        startSlot: shift.startSlot,
        endSlot: shift.endSlot,
        role: shift.role,
    }
}

export function toDayUserMap<AnyShiftType, AnyLocalityType>(
    items: AnyShiftType[],
    localities: AnyLocalityType[],
    getLocationId: (obj: AnyShiftType | AnyLocalityType) => string,
    getDayId: (obj: AnyShiftType | AnyLocalityType) => string,
    getUserId: (obj: AnyShiftType | AnyLocalityType) => string,
    convertShift: (obj: AnyShiftType) => StaticShift,
    convertLocality: (obj: AnyLocalityType) => LocalityOptionOrInherit,
    emptyLocalityResolution: "UNKNOWN" | "INHERIT"
): ScheduleMap {
    const map: ScheduleMap = new Map()
    for (const item of items) {
        const locationId = getLocationId(item)
        const dayId = getDayId(item)
        const userId = getUserId(item)
        ensureLocationAndDayAndUser(map, locationId, dayId, userId, emptyLocalityResolution)
        map.get(locationId)!.get(dayId)!.get(userId)!.shifts.push(convertShift(item))
    }
    for (const item of localities) {
        const locationId = getLocationId(item)
        const dayId = getDayId(item)
        const userId = getUserId(item)
        ensureLocationAndDayAndUser(map, locationId, dayId, userId, emptyLocalityResolution)
        map.get(locationId)!.get(dayId)!.get(userId)!.locality = convertLocality(item)
    }
    return map
}

export function ensureLocationAndDayAndUser(map: ScheduleMap, locationId: string, dayId: string, userId: string, initialLocality: LocalityOptionOrInherit): void {
    ensureMapForKey(map, locationId)
    const locationMap = map.get(locationId)!
    ensureMapForKey(locationMap, dayId)
    const dayMap = locationMap.get(dayId)!
    ensureScheduleItemForKey(dayMap, userId, initialLocality)
}

export function ensureScheduleItemForKey(map: Map<string, ScheduleItem>, key: string, initialLocality: LocalityOptionOrInherit) {
    if (!map.has(key)) {
        map.set(key, { shifts: [], locality: initialLocality })
    }
}

export function getShifts(map: ScheduleMap, locationId: string, dayId: string, userId: string): StaticShift[] {
    const mapForLocation: Map<string, Map<string, ScheduleItem>> = map.get(locationId) ?? new Map()
    const mapForDay: Map<string, ScheduleItem> = mapForLocation.get(dayId) ?? new Map()
    const scheduleItem = mapForDay.get(userId)
    return scheduleItem ? scheduleItem.shifts : []
}

export function toScheduleMap(shifts: ShiftType[], localities: LocalityType[], dayId: (shift: ShiftType | LocalityType) => string, emptyLocalityResolution: "UNKNOWN" | "INHERIT"): ScheduleMap {
    return toDayUserMap(
        shifts,
        localities,
        (s: ShiftType | LocalityType) => s.location.toString(),
        (s: ShiftType | LocalityType) => dayId(s),
        (s: ShiftType | LocalityType) => s.user.toString(),
        (s: ShiftType) => shiftToStaticShift(s),
        (l: LocalityType) => l.option,
        emptyLocalityResolution
    )
}

export function toScheduleMapDate(shifts: StaticShiftType[], localities: StaticLocalityType[], emptyLocalityResolution: "UNKNOWN" | "INHERIT"): ScheduleMap {
    return toDayUserMap(
        shifts,
        localities,
        (s: StaticShiftType | StaticLocalityType) => s.location.toString(),
        (s: StaticShiftType | StaticLocalityType) => s.date,
        (s: StaticShiftType | StaticLocalityType) => s.user.toString(),
        (s: StaticShiftType) => shiftToStaticShift(s),
        (l: StaticLocalityType) => l.option,
        emptyLocalityResolution
    )
}

export function toScheduleMapFromMutations(
    shiftMutations: ShiftMutationType[],
    localityMutations: LocalityMutationType[],
    dayId: (mutation: ShiftMutationType | LocalityMutationType) => string
): ScheduleMap {
    return toDayUserMap(
        shiftMutations,
        localityMutations,
        (m: ShiftMutationType | LocalityMutationType) => m.location.toString(),
        (m: ShiftMutationType | LocalityMutationType) => dayId(m),
        (m: ShiftMutationType | LocalityMutationType) => m.user.toString(),
        (m: ShiftMutationType) => shiftToStaticShift(m),
        (l: LocalityMutationType) => l.option,
        "INHERIT"
    )
}

export function toScheduleMapFromGeneratedMutations(breakMutations: GeneratedMutationType[]): ScheduleMap {
    return toDayUserMap(
        breakMutations,
        [],
        (m: GeneratedMutationType) => m.location.toString(),
        (m: GeneratedMutationType) => m.dayId,
        (m: GeneratedMutationType) => m.user.toString(),
        (m: GeneratedMutationType) => shiftToStaticShift(m),
        () => "INHERIT",
        "INHERIT"
    )
}

export function toScheduleMapFromPlan(plan: ShiftMutationsPlanType | ShiftTemplateMutationsPlanType) {
    const map: ScheduleMap = new Map()
    const locationId = plan.location.toString()

    for (const mutation of plan.mutations) {
        const userId = mutation.user.toString()

        const dayId = mutation.date ? getDayId(dateFromDjango(mutation.date)) : mutation.day.toString()
        ensureLocationAndDayAndUser(map, locationId, dayId, userId, "INHERIT")
        map.get(locationId)!
            .get(dayId)!
            .get(userId)!
            .shifts.push({
                startSlot: mutation.startSlot,
                endSlot: mutation.endSlot,
                role: plan.role || null,
            })
    }
    return map
}

export function toScheduleMapFromCopy(original: ScheduleMap, fromDay: string, toDays: string[], startSlot: number, endSlot: number) {
    const map: ScheduleMap = new Map()

    original.forEach((_, locationId) => {
        ensureMapForKey(map, locationId)
        const locationMap = map.get(locationId)!

        for (const dayId of toDays) {
            ensureMapForKey(locationMap, dayId)
            const dayMap = locationMap.get(dayId)!

            // Reset all shifts/localities in target day, in order to mutate all slots to free if there are no shifts in the fromDay.
            const originalDay: Map<string, ScheduleItem> = original.get(locationId)?.get(dayId) || new Map()
            originalDay.forEach((_, userId) => {
                dayMap.set(userId, {
                    shifts: [
                        {
                            startSlot: startSlot,
                            endSlot: endSlot,
                            role: 0,
                        },
                    ],
                    locality: "UNKNOWN",
                })
            })

            // Copy all shifts/localities from source day
            original
                .get(locationId)!
                .get(fromDay)!
                .forEach((originalUserItem, userId) => {
                    map.get(locationId)!
                        .get(dayId)!
                        .set(userId, {
                            shifts: mergeLists(
                                [
                                    {
                                        startSlot: startSlot,
                                        endSlot: endSlot,
                                        role: 0,
                                    },
                                ],
                                originalUserItem.shifts
                            ),
                            locality: originalUserItem.locality,
                        })
                })
        }
    })

    return map
}

export function hasScheduleItem(map: ScheduleMap, predicate: (item: ScheduleItem) => boolean): boolean {
    for (const locationMap of map.values()) {
        for (const dayMap of locationMap.values()) {
            for (const scheduleItem of dayMap.values()) {
                if (predicate(scheduleItem)) {
                    return true
                }
            }
        }
    }
    return false
}
