import { FC } from "react"

interface Props {
    weekCycle: number
}

const WeekOptions: FC<Props> = ({ weekCycle }) => {
    const components = []
    for (let i = 0; i < weekCycle; i++) {
        components.push(
            <option key={i} value={i}>
                Week {i + 1}
            </option>
        )
    }
    return components
}

export default WeekOptions
