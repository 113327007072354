import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import Table, { TableFilterDef } from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_MUTATIONS } from "../../contexts/TourSteps"
import { useColors, useIsAdmin, useRolesForSelectedLocation, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { dateFromDjango, getTimeString, isoPrint } from "../../helpers/DaysHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import { getRoleBadge } from "../../helpers/RolesHelper"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadShiftMutations } from "../../services/ShiftMutation"
import ShiftMutationType from "../../types/ShiftMutationType"

const MutationsPage: FC = () => {
    const colors = useColors()
    const location = useSelectedLocation()
    const users = useUsersForSelectedLocation()
    const roles = useRolesForSelectedLocation()
    const isAdmin = useIsAdmin()
    const { setActiveTab } = useTabs()
    const continueTour = useContinueTour()
    const resolveUserName = useResolveUserName(users)

    const columns = useMemo(
        (): ColumnDef<ShiftMutationType>[] => [
            {
                id: "user",
                header: "Medewerker",
                cell: ({ row }) => (
                    <div>
                        {!row.original.viewed ? <i className="fa-solid fa-circle me-2 text-extra-small text-blue" /> : null}
                        <Link to={`/wijzigingen/${row.original.id}`}>{resolveUserName(row.original.user)}</Link>
                    </div>
                ),
            },
            {
                id: "date",
                header: "Datum",
                cell: ({ row }) => {
                    return <span>{isoPrint(dateFromDjango(row.original.date))}</span>
                },
            },
            {
                id: "time",
                header: "Tijd",
                cell: ({ row }) => {
                    return (
                        <span className="text-bold">
                            {getTimeString("start", row.original.startSlot)} - {getTimeString("end", row.original.endSlot)}
                        </span>
                    )
                },
            },
            {
                id: "originalRole",
                header: "Wijziging",
                cell: ({ row }) => {
                    let originalRoleComponent = roles
                        ? getRoleBadge(
                              roles.find((r) => r.id === row.original.originalRole),
                              colors
                          )
                        : null
                    let targetRoleComponent = roles
                        ? getRoleBadge(
                              roles.find((r) => r.id === row.original.role),
                              colors
                          )
                        : null
                    return (
                        <div>
                            {originalRoleComponent}
                            <i className="fas fa-angle-right ms-2 me-2"></i>
                            {targetRoleComponent}
                        </div>
                    )
                },
            },
        ],
        [roles, colors, resolveUserName]
    )

    useEffect(() => setActiveTab("Mutations"), [setActiveTab])
    useEffect(() => continueTour(STEP_INDEX_MUTATIONS), [continueTour])

    const dateFilter = useMemo((): TableFilterDef => {
        return { id: "date", type: "daterange", label: "Periode:", initialValues: [{ key: "dateFrom", value: isoPrint(new Date()) }] }
    }, [])

    const userFilter = useMemo((): TableFilterDef => {
        return {
            id: "user",
            type: "picker",
            label: "Medewerker:",
            placeholder: "Naam...",
            options: users.map((user) => {
                return { key: user.id.toString(), title: `${user.firstName} ${user.lastName}` }
            }),
            initialValues: [{ key: "user", value: "" }],
        }
    }, [users])

    const viewedFilter = useMemo((): TableFilterDef => {
        return {
            id: "viewStatus",
            type: "select",
            label: "Status:",
            placeholder: "Status...",
            options: [
                { key: "NEW", title: "Ongelezen" },
                { key: "READ", title: "Gelezen" },
            ],
            initialValues: [{ key: "viewStatus", value: "" }],
        }
    }, [])

    const viewStatusAtFilter = useMemo((): TableFilterDef => {
        return {
            id: "viewStatusAt",
            type: "hidden",
            initialValues: [{ key: "viewStatusAt", value: new Date().getTime().toString() }],
        }
    }, [])

    const markViewedFilter = useMemo((): TableFilterDef => {
        return {
            id: "markViewed",
            type: "hidden",
            initialValues: [{ key: "markViewed", value: "true" }],
        }
    }, [])

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="Mutations">
            <div className="mb-4">
                <h1 className="h2">Wijzigingen</h1>
            </div>
            <Table
                id={`${location.id}_ShiftMutations`}
                columns={columns}
                buttons={[]}
                filters={
                    isAdmin
                        ? [locationFilter, dateFilter, userFilter, viewedFilter, viewStatusAtFilter, markViewedFilter]
                        : [locationFilter, dateFilter, viewedFilter, viewStatusAtFilter, markViewedFilter]
                }
                fetchData={loadShiftMutations}
                subject="wijzigingen"
                minWidth="600px"
            />
        </RegularPage>
    )
}

export default MutationsPage
