import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Badge } from "react-bootstrap"
import { ScrollSync, ScrollSyncNode } from "scroll-sync-react"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_SCHEDULE } from "../../contexts/TourSteps"
import { useIsAdmin } from "../../contexts/UserSettingsContext"
import * as DaysHelper from "../../helpers/DaysHelper"
import { ScheduleItem } from "../../helpers/ScheduleMapHelper"
import { Cell, CellSelection, getClickedCell } from "../../helpers/editor/ShiftEditorHelper"
import EditorDivider from "../editor/EditorDivider"
import EditorFilling from "../editor/EditorFilling"
import OccupationTotals from "./OccupationTotals"
import styles from "./ShiftsEditor.module.scss"
import ShiftEditorControls from "./ShiftsEditorControls"
import ShiftsEditorUser from "./ShiftsEditorUser"
import OccupationTimeSlots from "./occupationBar/OccupationTimeSlots"

const ShiftsEditor: FC = () => {
    const { loading, applyMutation, setLocality } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()
    const continueTour = useContinueTour()

    const [selectedOccupationSlot, setSelectedOccupationSlot] = useState<number | undefined>()
    const [cellSelection, setCellSelection] = useState<CellSelection>({ from: undefined, to: undefined })
    const [planningRole, setPlanningRole] = useState<number | undefined>()
    const isAdmin = useIsAdmin()

    const { revisionId, dayId, location, roles, users, enabledRoles, submittedHiringAvailabilities, hiringAvailabilities } = editorState
    const { startSlot, endSlot } = location

    const updateSelection = useCallback(
        (selectedFrom: Cell | undefined, selectedTo: Cell | undefined) => {
            setSelectedOccupationSlot(undefined)
            setCellSelection({ from: selectedFrom, to: selectedTo })
            setPlanningRole(undefined)
        },
        [setSelectedOccupationSlot, setCellSelection, setPlanningRole]
    )

    const selectCell = useCallback(
        (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
            event.preventDefault()
            const target = event.target as HTMLDivElement
            const cell = getClickedCell(target.id)
            if (cellSelection.from !== undefined && cellSelection.to === undefined && cellSelection.from.dayId === cell.dayId && cellSelection.from.user === cell.user) {
                if (cell.slot < cellSelection.from.slot) {
                    updateSelection(cell, cellSelection.from)
                } else {
                    updateSelection(cellSelection.from, cell)
                }
            } else {
                updateSelection(cell, undefined)
            }
        },
        [updateSelection, cellSelection]
    )

    const clearSelection = useCallback(() => updateSelection(undefined, undefined), [updateSelection])

    const setSelectedSlot = useCallback(
        (slot: number | undefined) => {
            setSelectedOccupationSlot(slot)
            setCellSelection({ from: undefined, to: undefined })
            setPlanningRole(undefined)
        },
        [setSelectedOccupationSlot, setCellSelection, setPlanningRole]
    )

    const setShiftData = useCallback(
        (user: number, cellFrom: number, cellTo: number, value: number | null) => {
            applyMutation(user, cellFrom, cellTo, value)
            clearSelection()
        },
        [applyMutation, clearSelection]
    )

    const setShift = useCallback(
        (value: number | null) => {
            if (cellSelection.from === undefined) {
                return
            }
            const { user } = cellSelection.from
            const cellFrom = cellSelection.from.slot
            const cellTo = cellSelection.to !== undefined ? cellSelection.to.slot : cellSelection.from.slot

            setShiftData(user, cellFrom, cellTo, value)
        },
        [cellSelection, setShiftData]
    )

    useEffect(clearSelection, [dayId])

    const locationId = useMemo(() => {
        return editorState.location.id.toString()
    }, [editorState.location])

    const daySchedule: Map<string, ScheduleItem> = useMemo(() => {
        return editorState.mergedSchedule && editorState.mergedSchedule.has(locationId) && editorState.mergedSchedule.get(locationId)!.has(dayId)
            ? new Map(editorState.mergedSchedule.get(locationId)!.get(dayId)!)
            : new Map()
    }, [editorState.mergedSchedule, locationId, dayId])

    useEffect(() => {
        if (!loading) {
            continueTour(STEP_INDEX_SCHEDULE)
        }
    }, [continueTour, loading])

    const errorMessage = useMemo(() => {
        if (editorState.errorMessage === "shiftTemplate") {
            return "Er is geen basisrooster actief voor de gekozen datum."
        }
    }, [editorState.errorMessage])

    if (!editorState.mergedSchedule || editorState.errorMessage === "holiday") {
        return
    }

    if (!!editorState.errorMessage) {
        return (
            <Badge bg="danger-light" text="dark" className="mb-3">
                <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                {errorMessage}
            </Badge>
        )
    }

    return (
        <ScrollSync>
            <div className={styles.scrollContainer}>
                <div className="editor-sticky-add-on editor-sticky-header">
                    <ScrollSyncNode>
                        <div className="overflow-auto no-scrollbar">
                            <div className="editor-container">
                                <div className="editor-add-on editor-head">
                                    <div className="editor-cell-title" />
                                    {DaysHelper.getTimeSlots(startSlot, endSlot, "regular")}
                                </div>
                                <OccupationTimeSlots
                                    selectedSlot={selectedOccupationSlot}
                                    setSelectedSlot={setSelectedSlot}
                                    planningRole={planningRole}
                                    setPlanningRole={setPlanningRole}
                                    daySchedule={daySchedule}
                                />
                            </div>
                        </div>
                    </ScrollSyncNode>
                </div>
                <ScrollSyncNode>
                    <div className="overflow-auto no-scrollbar">
                        <div className="editor-container editor-content-container" data-cy="editorRows">
                            {users.map((user, i) => (
                                <ShiftsEditorUser
                                    key={`${dayId}#${i}#${revisionId}`}
                                    dayId={dayId}
                                    user={user}
                                    startSlot={startSlot}
                                    endSlot={endSlot}
                                    selectCell={selectCell}
                                    scheduleItem={daySchedule.get(user.id.toString())}
                                    cellSelection={cellSelection}
                                    roles={roles}
                                    enabledRoles={enabledRoles}
                                    enabledAvailabilities={submittedHiringAvailabilities}
                                    hiringAvailability={hiringAvailabilities.find((ha) => ha.user === user.id)}
                                    setLocality={setLocality}
                                />
                            ))}
                            <EditorDivider order={998} />
                            <EditorFilling startSlot={startSlot} endSlot={endSlot} order={99997} />
                            <EditorDivider order={99998} />
                            <div className="editor-cell-container" style={{ order: 99999 }}>
                                <div className="editor-cell-title text-bold text-muted">
                                    <span className="editor-cell-title-label">Totaal</span>
                                </div>
                                <OccupationTotals key={"totals#" + dayId} daySchedule={daySchedule} startSlot={startSlot} endSlot={endSlot} roles={roles} enabledRoles={enabledRoles} />
                            </div>
                        </div>
                    </div>
                </ScrollSyncNode>
                {isAdmin ? (
                    <div className="editor-sticky-add-on editor-sticky-footer">
                        <ScrollSyncNode>
                            <div className="overflow-auto">
                                <div className="editor-container">
                                    <div className="editor-add-on">
                                        <div className="editor-head-title"></div>
                                        <ShiftEditorControls roles={roles} setShift={setShift} clearSelection={clearSelection} />
                                    </div>
                                </div>
                            </div>
                        </ScrollSyncNode>
                    </div>
                ) : null}
            </div>
        </ScrollSync>
    )
}

export default ShiftsEditor
