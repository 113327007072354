import { FC, useMemo } from "react"
import { useColors } from "../../../contexts/UserSettingsContext"
import { getCSSColor } from "../../../helpers/ColorHelper"
import RoleType from "../../../types/RoleType"
import styles from "./StaticWeekTimeLineItem.module.scss"

interface Props {
    startSlot: number
    endSlot: number
    role?: RoleType
    isFillingBreak?: boolean
}

const StaticWeekTimeLineItem: FC<Props> = ({ startSlot, endSlot, role, isFillingBreak }) => {
    const colors = useColors()

    const color = useMemo(() => {
        if (isFillingBreak) {
            return "#f2f6f9"
        } else if (!role) {
            return undefined
        } else {
            return getCSSColor(colors.get(role.color)!)
        }
    }, [isFillingBreak, colors, role])

    const label = useMemo(() => {
        if (isFillingBreak) {
            return "Pauze"
        } else {
            return role?.name
        }
    }, [isFillingBreak, role])

    const flex = useMemo(() => `${(endSlot + 1 - startSlot) * 10} ${(endSlot + 1 - startSlot) * 10} 1px`, [startSlot, endSlot])

    return (
        <div className={styles.timeLineItem} style={{ flex: flex, backgroundColor: color }} data-cy="timeLineItem">
            {label ? <div className={styles.timeLineItemLabel}>{label}</div> : null}
        </div>
    )
}

export default StaticWeekTimeLineItem
