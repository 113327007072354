import { FilterSelectOption } from "../components/table/TableFilters"
import { getUsers } from "../services/User"
import CompanyType from "../types/CompanyType"
import LocationType from "../types/LocationType"
import RoleType from "../types/RoleType"
import { EditUserAvailabilityType, RolePreferenceType, UserType } from "../types/UserType"
import { dateFromDjango, getTimeString, isoPrint } from "./DaysHelper"

export function getUserOptions(users: { id: number; firstName: string; lastName: string }[]) {
    if (!users) {
        return []
    }
    return users.map((user) => {
        return {
            id: user.id.toString(),
            name: user.firstName + " " + user.lastName,
        }
    })
}

export function getUserFilterOptions(users: UserType[]): FilterSelectOption[] {
    if (!users) {
        return []
    }
    return users.map((user) => {
        return {
            key: user.id.toString(),
            title: user.firstName + " " + user.lastName,
        }
    })
}

export function loadUsersForDate(location: LocationType, date: Date | null): Promise<UserType[]> {
    if (!date) {
        return Promise.resolve([])
    }
    return new Promise((resolve, reject) => {
        getUsers({
            pageIndex: 0,
            pageSize: 999,
            filters: [
                { key: "employableat", value: isoPrint(date) },
                { key: "location", value: location.id.toString() },
            ],
        })
            .then((response) => {
                resolve(response.data.items)
            })
            .catch(reject)
    })
}

export const initAvailabilities = (currentCompany: CompanyType, weekCycle: number) => {
    const availabilities: EditUserAvailabilityType[] = []
    for (let i = 0; i < weekCycle * 7; i++) {
        availabilities.push({
            startTime: getTimeString("start", currentCompany.defaultStartSlotUser),
            endTime: getTimeString("end", currentCompany.defaultEndSlotUser),
            day: i,
        })
    }
    return availabilities
}

export const getUserTypeOptions = (companyModules: string[]) => {
    const options = [
        {
            id: "ADMIN",
            name: "Beheerder",
        },
        { id: "REGULAR", name: "Standaard" },
    ]
    if (companyModules.includes("EXTERNAL_HIRES")) {
        options.push({ id: "EXTERNAL", name: "Inleen" })
    }
    return options
}

export const initRolePreferences = (roles: RoleType[]): RolePreferenceType[] => {
    return roles
        ? roles
              .filter((r) => r.isDefaultPreference)
              .map((r) => {
                  return { role: r.id, priority: 1 }
              })
        : []
}

export const isEmployable = (user: UserType, date: Date) => {
    if (user.employableFrom && dateFromDjango(user.employableFrom) > date) {
        return false
    }
    if (user.employableTo && dateFromDjango(user.employableTo) < date) {
        return false
    }
    return true
}

export const isEmployableNow = (user: UserType) => {
    return isEmployable(user, new Date())
}

export const getNonExternalEmployableUsers = (users: UserType[], date: Date) => {
    return users.filter((u) => u.type !== "EXTERNAL").filter((u) => isEmployable(u, date))
}
