import { FC, useCallback, useContext, useEffect } from "react"
import { Badge, Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { setOptionalError } from "../helpers/FormHelper"
import { start } from "../services/User"

interface Inputs {
    password1: string
    password2: string
}

interface StartAccountPageProps {
    initCompany: boolean
}

const StartAccountPage: FC<StartAccountPageProps> = ({ initCompany }) => {
    const navigate = useNavigate()
    const params = useParams()
    const isAuthenticated = useIsAuthenticated()
    const { reloadUserSettings } = useContext(UserSettingsContext)
    const {
        register,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onFailure = useCallback((error: any) => {
        const data = error.response && error.response.data ? error.response.data : {}
        setOptionalError(setError, "password1", data.new_password1)
        setOptionalError(setError, "password2", data.new_password2)
        setOptionalError(setError, "root", data.nonFieldErrors)
    }, [])

    const onSuccess = useCallback(() => {
        reloadUserSettings()
    }, [reloadUserSettings])

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ password1, password2 }) => {
            const { uid, token } = params
            start(password1, password2, uid!, token!).then(onSuccess).catch(onFailure)
        },
        [params, onSuccess, onFailure]
    )

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [isAuthenticated])

    return (
        <FullPageCard>
            <div className="card-header-bar">
                <div className="card-header-bar-item">
                    <h1 className="h2">Beginnen met Rooster Planner</h1>
                </div>
                {initCompany ? (
                    <div className="card-header-bar-item end">
                        <Badge>1/3</Badge>
                    </div>
                ) : null}
            </div>

            <p>Stel een wachtwoord in om te beginnen met Rooster Planner</p>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>Wachtwoord</Form.Label>
                    <Form.Control type="password" {...register("password1")} isInvalid={!!errors.password1} data-cy="password1" autoFocus />
                    <Form.Control.Feedback type="invalid" data-cy="password1_errors">
                        {errors.password1?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Wachtwoord nogmaals</Form.Label>
                    <Form.Control type="password" {...register("password2")} isInvalid={!!errors.password2} data-cy="password2" />
                    <Form.Control.Feedback type="invalid" data-cy="password2_errors">
                        {errors.password2?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 flex-row">
                    <Button type="submit" data-cy="start-account">
                        {initCompany ? "Volgende" : "Start"}
                    </Button>
                    {!initCompany ? (
                        <small className="end">
                            <span className="text-muted">Terug naar </span>
                            <Link to="/inloggen" className="fw-bold">
                                Inloggen
                            </Link>
                        </small>
                    ) : null}
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default StartAccountPage
