import { FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { activate } from "../services/User"

const ActivateAccountPage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const isAuthenticated = useIsAuthenticated()
    const { userSettings, reloadUserSettings } = useContext(UserSettingsContext)

    const [formErrors, setFormErrors] = useState<string[]>([])

    const onFailure = useCallback((error: any) => {
        const data = error.response && error.response.data ? error.response.data : {}
        setFormErrors(data.nonFieldErrors || ["Er is iets misgegaan"])
    }, [])

    const onSuccess = useCallback(() => {
        reloadUserSettings()
    }, [reloadUserSettings])

    const onSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault()
            const { uid, token } = params
            activate(uid!, token!).then(onSuccess).catch(onFailure)
        },
        [params, onSuccess, onFailure]
    )

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [userSettings])

    return (
        <FullPageCard>
            <h1 className="h2">Activeer je account!</h1>
            <p>Bevestig dat je wilt beginnen met Rooster Planner</p>
            <Form noValidate onSubmit={onSubmit}>
                <Form.Group className="mb-3 flex-row">
                    <Button type="submit" data-cy="activate-account">
                        Bevestig
                    </Button>
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!formErrors} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {formErrors}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default ActivateAccountPage
