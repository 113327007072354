import { SlotType } from "../../components/shiftEditor/ShiftsEditorUser"
import ColorType from "../../types/ColorType"
import { HiringAvailabilityStatusWithUnknown } from "../../types/HiringAvailabilityType"

export interface Cell {
    dayId: string
    user: number
    slot: number
}

export interface CellSelection {
    from: Cell | undefined
    to: Cell | undefined
}

export interface ShiftEditorCellDetails {
    color: ColorType | null
    selected: boolean
    isFirstOfShift: boolean
    isFirstOfBreak: boolean
    roleName: string
    nthColor: number
    isMuted: boolean
    slotType: SlotType
    availability: HiringAvailabilityStatusWithUnknown | null
}

export const isSelected = (cellSelection: CellSelection, userId: number, cell: number): boolean => {
    const userMatches = !!cellSelection.from && cellSelection.from!.user === userId
    const cellAfterStart = !!cellSelection.from && cellSelection.from!.slot <= cell
    const cellBeforeEnd = !!cellSelection.to && cellSelection.to!.slot >= cell
    const cellIsStartWithoutEnd = !cellSelection.to && !!cellSelection.from && cellSelection.from!.slot === cell
    return userMatches && cellAfterStart && (cellIsStartWithoutEnd || cellBeforeEnd)
}

export const getClickedCell = (id: string) => {
    const idRegex = /(\d+)#(\d+)#(\d+)/g
    const match = idRegex.exec(id)!
    return {
        dayId: match[1],
        user: parseInt(match[2]),
        slot: parseInt(match[3]),
    }
}

export const hasPositiveInNextValues = (values: number[], i: number, amount: number) => {
    let j = i + 1
    while (j <= Math.min(values.length, i + amount)) {
        if (values[j] > 0) {
            return true
        }
        j++
    }
    return false
}

export const isStartOfBreak = (values: number[], i: number) => {
    return values[i] === 0 && i > 0 && values[i - 1] > 0 && hasPositiveInNextValues(values, i, 4)
}

const leftDistanceToPositiveValue = (values: number[], i: number) => {
    for (let j = i - 1; j >= Math.max(0, i - 4); j--) {
        if (values[j] > 0) {
            return i - j - 1
        }
    }
    return 99
}

const rightDistanceToPositiveValue = (values: number[], i: number) => {
    for (let j = i + 1; j <= Math.min(values.length - 1, i + 4); j++) {
        if (values[j] > 0) {
            return j - i - 1
        }
    }
    return 99
}

export const isBreak = (values: number[], i: number) => {
    return values[i] === 0 && leftDistanceToPositiveValue(values, i) + rightDistanceToPositiveValue(values, i) <= 3
}
