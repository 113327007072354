import { FC, useCallback, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import ShiftEditor from "../../components/shiftEditor/ShiftEditor"
import { ScheduleEditorContextProvider } from "../../contexts/ScheduleEditorContext"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getFirstEnabledDayIndex } from "../../helpers/DaysHelper"
import { loadOccupationsForShiftTemplate } from "../../helpers/OccupationHelper"
import { ScheduleMap } from "../../helpers/ScheduleMapHelper"
import { loadScheduleForShiftTemplate } from "../../helpers/ShiftHelper"
import { getShiftTemplate } from "../../services/ShiftTemplate"
import { createTaskShiftTemplateMutationProposal, loadTask, TaskShiftTemplateMutationProposalResult, TaskType } from "../../services/Task"
import LocationType from "../../types/LocationType"
import ShiftTemplateType from "../../types/ShiftTemplateType"

const UpdateShiftTemplatePage: FC = () => {
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const initialDay = useMemo(() => getFirstEnabledDayIndex(location.enabledDays), [location])
    const navigate = useNavigate()

    const loadShiftTemplate = useCallback(() => {
        return new Promise<ShiftTemplateType>((resolve) => {
            getShiftTemplate(id).then((response) => {
                if (response.data.location !== location.id) {
                    navigate("/basisroosters")
                    return
                }
                resolve(response.data)
            })
        })
    }, [id])
    const loadSchedule = useCallback((shiftTemplate: ShiftTemplateType | null, _location: LocationType | null, _date: Date | null) => loadScheduleForShiftTemplate(shiftTemplate!), [])
    const loadOccupations = useCallback((shiftTemplate: ShiftTemplateType | null, _location: LocationType | null, _date: Date | null) => loadOccupationsForShiftTemplate(shiftTemplate!), [])
    const loadProposal = useCallback((id: number) => loadTask<TaskShiftTemplateMutationProposalResult>(id), [])
    const generateProposal = useCallback(
        (shiftTemplate: ShiftTemplateType | null, day: number | null, date: Date | null, slot: number, role: number, newShiftMutations: ScheduleMap) => {
            return new Promise<TaskType<TaskShiftTemplateMutationProposalResult>>((resolve) => {
                createTaskShiftTemplateMutationProposal({
                    role,
                    day: day!,
                    location: location.id,
                    shiftTemplate: shiftTemplate!.id,
                    slot,
                    newShiftMutations,
                }).then((response) => resolve(response.data))
            })
        },
        [location]
    )

    useEffect(() => setActiveTab("ShiftTemplates"), [setActiveTab])

    return (
        <RegularPage id="UpdateShiftTemplate" breadCrumbs={[{ title: "Basisroosters", link: "/basisroosters" }, { title: id.toString() }]}>
            <ScheduleEditorContextProvider
                initialLocation={location}
                initialDay={initialDay}
                loadShiftTemplate={loadShiftTemplate}
                loadSchedule={loadSchedule}
                loadOccupations={loadOccupations}
                generateProposal={generateProposal}
                loadProposal={loadProposal}
            >
                <ShiftEditor mode="DAYS" />
            </ScheduleEditorContextProvider>
        </RegularPage>
    )
}

export default UpdateShiftTemplatePage
