import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEvent, FC, MouseEvent, useCallback, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useRolesForSelectedLocation } from "../../../contexts/UserSettingsContext"
import { Inputs } from "../EditOccupationTemplateDetailsPage"

interface Props {
    rowIndex: number
    watch: UseFormWatch<Inputs>
    setValue: UseFormSetValue<Inputs>
}

const UnexusFileRowView: FC<Props> = ({ rowIndex, watch, setValue }) => {
    const roles = useRolesForSelectedLocation()
    const unexusFileRows = watch("unexusFileRows")
    const row = useMemo(() => unexusFileRows[rowIndex], [unexusFileRows, rowIndex])

    const handleFileRoleSelect = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement

            const rows = [...unexusFileRows]
            row.role = target.value
            setValue("unexusFileRows", rows)
        },
        [unexusFileRows, row, setValue]
    )

    const handleFileSelect = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement

            const rows = [...unexusFileRows]
            row.file = target.files?.item(0)
            setValue("unexusFileRows", rows)
        },
        [unexusFileRows, row, setValue]
    )

    const deleteRow = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const rows = [...unexusFileRows]
            const index = rows.findIndex((r) => r.id === row.id)
            rows.splice(index, 1)
            setValue("unexusFileRows", rows)
        },
        [unexusFileRows, row, setValue]
    )

    return (
        <li className="mb-2" style={{ display: "flex", alignItems: "center" }}>
            <div className="me-2">
                <Form.Select value={row.role} onChange={handleFileRoleSelect}>
                    <option value="">Kies taak...</option>
                    {roles.map(({ id, name }) => (
                        <option key={id} value={id.toString()}>
                            {name}
                        </option>
                    ))}
                </Form.Select>
            </div>
            <input id={"select_f_" + row.id} className="form-control me-2" type="file" onChange={handleFileSelect} />
            <Button type="button" id={"delete_b_" + row.id} variant="link" onClick={deleteRow}>
                <FontAwesomeIcon id={"delete_i_" + row.id} icon={faTrashAlt} />
            </Button>
        </li>
    )
}

export default UnexusFileRowView
