import { FC, useCallback, useMemo } from "react"
import { useScheduleEditorState } from "../../../../contexts/ScheduleEditorContext"
import { getProgressColor } from "../../../../helpers/ColorHelper"
import { getTimeString } from "../../../../helpers/DaysHelper"
import { ShiftMutationOptionType } from "../../../../services/Task"

interface Props {
    role: number
    option: ShiftMutationOptionType
    applyProposalOption: (user: number, startSlot: number, endSlot: number, role: number | null) => void
}

const RolePlanningOption: FC<Props> = ({ role, option, applyProposalOption }) => {
    const editorState = useScheduleEditorState()
    const { users } = editorState

    const userName = useMemo(() => {
        const user = users.find((u) => u.id === option.user)
        return user ? `${user.firstName} ${user.lastName}` : ""
    }, [users, option])

    const applyOption = useCallback(() => {
        const { user, startSlot, endSlot } = option
        applyProposalOption(user, startSlot, endSlot, role)
    }, [option, applyProposalOption, role])

    return (
        <tr className="occupation-details-row" onClick={applyOption}>
            <td className="occupation-time-slot-detail text-bold">{userName}</td>
            <td className="occupation-time-slot-detail text-bold text-muted ps-3">
                {getTimeString("start", option.startSlot)} - {getTimeString("end", option.endSlot)}
            </td>
            <td className="occupation-time-slot-detail ps-3">
                <div className="progress" style={{ width: "50px", height: "0.5rem" }}>
                    <div
                        className="progress-bar text-bold"
                        role="progressbar"
                        aria-valuenow={option.score}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{
                            width: option.score + "%",
                            backgroundColor: getProgressColor(option.score / 100.0),
                        }}
                    ></div>
                </div>
            </td>
        </tr>
    )
}

export default RolePlanningOption
