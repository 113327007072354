import { dateToDjango } from "../helpers/DaysHelper"
import { toObject } from "../helpers/MapHelper"
import { getRoleGroupIds, getRoleIds } from "../helpers/RolesHelper"
import { ScheduleMap } from "../helpers/ScheduleMapHelper"
import GeneratedMutationType from "../types/GeneratedMutationType"
import api from "./Api"

export interface TaskType<TaskResultType> {
    id: number
    done: boolean
    progress: number
    success: boolean
    result: TaskResultType
    resultUrl: string
    errorId?: string
}

export interface TaskResultWithRoles {
    roleUids: string[]
}

export type IntervalType = "QUARTER" | "DAY" | "WEEK" | "MONTH"

export const intervalOptions: { id: IntervalType; name: string }[] = [
    { id: "DAY", name: "Per dag" },
    { id: "WEEK", name: "Per week" },
    { id: "MONTH", name: "Per maand" },
]

// ----- Create inputs -----

export interface CreateTaskRoleTimeStats {
    location: number
    interval: IntervalType
    roleUids: string[]
    fromDate: Date
    toDate: Date
}

export interface CreateTaskUserTimeStats {
    location: number
    interval: IntervalType
    users: string[]
    fromDate: Date
    toDate: Date
}

export interface CreateTaskForecastStats {
    location: number
    interval: IntervalType
    roleUids: string[]
    fromDate: Date
    toDate: Date
}

export interface CreateTaskBreakMutations {
    mode: "DAYS" | "DATERANGE"
    location: number
    shiftTemplate?: number
    fromDate: Date
    toDate: Date
    days?: string
    breaks: string[]
    users: string[]
}

export interface CreateTaskShiftMutationProposal {
    role: number
    date: Date
    slot: number
    location: number
    newShiftMutations: ScheduleMap
}

export interface CreateTaskShiftTemplateMutationProposal {
    role: number
    day: number
    slot: number
    location: number
    shiftTemplate: number
    newShiftMutations: ScheduleMap
}

export interface CreateTaskShiftAdditions {
    location: number
    shiftTemplate: number
    newShiftMutations: ScheduleMap
    days: string
    roles: number[]
    users: number[]
}

// ----- Results -----

export interface TaskRoleTimeResult {
    interval: IntervalType
    fromDate: string
    toDate: string
    roleUids: string[]
    roleData: any[]
    rolesAggregate: any
}

export interface TaskUserTimeResult {
    interval: IntervalType
    fromDate: string
    toDate: string
    roleUids: string[]
    roleData: any[]
    rolesAggregate: any
}

export interface TaskForecastResult {
    interval: IntervalType
    fromDate: string
    toDate: string
    roleUids: string[]
    roleData: any
    rolesAggregate: any
}

export interface TaskGeneratedMutationsResult {
    mutations: GeneratedMutationType[]
}

export interface TaskBreakMutationsResult extends TaskGeneratedMutationsResult {}

export interface TaskShiftsResult {}

export interface ShiftMutationOptionType {
    user: number
    startSlot: number
    endSlot: number
    score: number
}

export interface TaskShiftMutationProposalResult {
    role: number
    options: ShiftMutationOptionType[]
}

export interface TaskShiftTemplateMutationProposalResult {
    role: number
    options: ShiftMutationOptionType[]
}

export interface TaskShiftAdditionsResult extends TaskGeneratedMutationsResult {}

// ----- Create functions -----

export function createTaskRoleTimeStats(details: CreateTaskRoleTimeStats) {
    const { location, interval, roleUids, fromDate, toDate } = details
    const body = {
        location,
        interval,
        roles: getRoleIds(roleUids),
        roleGroups: getRoleGroupIds(roleUids),
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
    }
    return api.post<TaskType<TaskRoleTimeResult>>(`/tasks/?type=ROLE_TIME`, body)
}

export function createTaskUserTimeStats(details: CreateTaskUserTimeStats) {
    const { location, interval, users, fromDate, toDate } = details
    const body = {
        location,
        interval,
        users: users.map((id) => parseInt(id)),
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
    }
    return api.post<TaskType<TaskUserTimeResult>>(`/tasks/?type=USER_TIME`, body)
}

export function createTaskForecastStatus(details: CreateTaskForecastStats) {
    const { location, interval, roleUids, fromDate, toDate } = details
    const body = {
        location,
        interval,
        roles: getRoleIds(roleUids),
        roleGroups: getRoleGroupIds(roleUids),
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
    }
    return api.post<TaskType<TaskForecastResult>>(`/tasks/?type=FORECAST`, body)
}

export function createTaskBreakMutations(details: CreateTaskBreakMutations) {
    const { mode, location, shiftTemplate, fromDate, toDate, days, breaks, users } = details
    const body = {
        mode,
        location,
        shiftTemplate,
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
        days: days,
        breaks: breaks.map((id) => parseInt(id)),
        users: users.map((id) => parseInt(id)),
    }
    return api.post<TaskType<TaskBreakMutationsResult>>(`/tasks/?type=BREAK_MUTATIONS`, body)
}

export function createTaskShiftMutationProposal(details: CreateTaskShiftMutationProposal) {
    const { role, date, location, slot, newShiftMutations } = details
    const body = {
        role,
        date: dateToDjango(date),
        location,
        slot,
        newShiftMutations: toObject(newShiftMutations),
    }
    return api.post<TaskType<TaskShiftMutationProposalResult>>(`/tasks/?type=SHIFT_MUTATION_PROPOSAL`, body)
}

export function createTaskShiftTemplateMutationProposal(details: CreateTaskShiftTemplateMutationProposal) {
    const { role, day, location, shiftTemplate, slot, newShiftMutations } = details
    const body = {
        role,
        day,
        location,
        shiftTemplate,
        slot,
        newShiftMutations: toObject(newShiftMutations),
    }
    return api.post<TaskType<TaskShiftTemplateMutationProposalResult>>(`/tasks/?type=SHIFT_TEMPLATE_MUTATION_PROPOSAL`, body)
}

export function createTaskShiftAdditions(details: CreateTaskShiftAdditions) {
    const { location, shiftTemplate, newShiftMutations, days, users, roles } = details
    const body = {
        location,
        shiftTemplate,
        newShiftMutations: toObject(newShiftMutations),
        days,
        users,
        roles,
    }

    return api.post<TaskType<TaskShiftAdditionsResult>>(`/tasks/?type=SHIFT_ADDITIONS`, body)
}

// ----- Retrieval -----

export function loadTask<TaskResultType>(id: number) {
    return new Promise<TaskType<TaskResultType>>((resolve) => {
        getTask<TaskResultType>(id).then((response) => resolve(response.data))
    })
}

export function getTask<TaskResultType>(id: number) {
    return api.get<TaskType<TaskResultType>>(`/tasks/${id}/`)
}
