import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Dropdown } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { ScheduleEditorContext } from "../../contexts/ScheduleEditorContext"
import { useIsAdmin } from "../../contexts/UserSettingsContext"
import { getOptionalIntParam } from "../../helpers/ParamHelper"
import DateNoteType from "../../types/DateNoteType"
import FormErrorMessages from "../form/FormErrorMessages"
import FormSuccessMessage from "../form/FormSuccessMessage"
import ShiftPlanner from "../shiftPlanner/ShiftPlanner"
import AbsencePlanner from "./absencePlanner/AbsencePlanner"
import ScheduleApproval from "./approval/ScheduleApproval"
import BreakPlanner from "./breakPlanner/BreakPlanner"
import CopyShiftDayModal from "./CopyShiftDayModal"
import EditDateNoteModal from "./dateNoteModal/EditDateNoteModal"
import ScheduleDateNotes from "./ScheduleDateNotes"
import ScheduleDateSelector from "./ScheduleDateSelector"
import ScheduleDaySelector from "./ScheduleDaySelector"
import ScheduleFilters from "./ScheduleFilters"
import ScheduleLoader from "./ScheduleLoader"
import ScheduleValidationMessages from "./ScheduleValidationMessages"
import ScheduleWarnings from "./ScheduleWarnings"
import SendNotificationsView from "./sendNotifications/SendNotificationsView"
import ShiftGenerator from "./shiftGenerator/ShiftGenerator"
import ShiftsEditor from "./ShiftsEditor"
import ShiftTemplateEditorHead from "./ShiftTemplateEditorHead"
import ShiftTemplatePublishMessage from "./ShiftTemplatePublishMessage"
import ShiftTemplateSaveButtons from "./ShiftTemplateSaveButtons"

interface Props {
    mode: "DAYS" | "DATERANGE"
}

const ShiftEditor: FC<Props> = ({ mode }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const isAdmin = useIsAdmin()

    const absenceRequest = useMemo(() => getOptionalIntParam("absencerequest", searchParams), [searchParams])

    const { editorState } = useContext(ScheduleEditorContext)

    const [succeededId, setSucceededId] = useState(0)
    const [formErrors, setFormErrors] = useState<string[]>([])
    const [editingDateNote, setEditingDateNote] = useState<DateNoteType | null>(null)

    const [showBulkPlanner, setShowBulkPlanner] = useState(false)
    const [showDateNoteView, setShowDateNoteView] = useState(false)
    const [showNotificationsView, setShowNotificationsView] = useState(false)
    const [showCopyModal, setShowCopyModal] = useState(false)
    const [showBreakPlanner, setShowBreakPlanner] = useState(false)
    const [showShiftGenerator, setShowShiftGenerator] = useState(false)
    const [showApproval, setShowApproval] = useState(false)
    const [showAbsencePlanner, setShowAbsencePlanner] = useState(!!absenceRequest)

    const openBulkPlanner = useCallback(() => setShowBulkPlanner(true), [setShowBulkPlanner])
    const closeBulkPlanner = useCallback(() => setShowBulkPlanner(false), [setShowBulkPlanner])

    const openDateNoteView = useCallback(() => setShowDateNoteView(true), [setShowDateNoteView])
    const closeDateNoteView = useCallback(() => {
        setEditingDateNote(null)
        setShowDateNoteView(false)
    }, [setShowDateNoteView])

    const openNotificationsView = useCallback(() => setShowNotificationsView(true), [setShowNotificationsView])
    const closeNotificationsView = useCallback(() => setShowNotificationsView(false), [setShowNotificationsView])

    const openCopyModal = useCallback(() => setShowCopyModal(true), [setShowCopyModal])
    const closeCopyModal = useCallback(() => setShowCopyModal(false), [setShowCopyModal])

    const openBreakPlanner = useCallback(() => setShowBreakPlanner(true), [setShowBreakPlanner])
    const closeBreakPlanner = useCallback(() => setShowBreakPlanner(false), [setShowBreakPlanner])

    const openShiftGenerator = useCallback(() => setShowShiftGenerator(true), [setShowShiftGenerator])
    const closeShiftGenerator = useCallback(() => setShowShiftGenerator(false), [setShowShiftGenerator])

    const closeAbsencePlanner = useCallback(() => setShowAbsencePlanner(false), [setShowAbsencePlanner])

    const openApproval = useCallback(() => setShowApproval(true), [setShowApproval])
    const closeApproval = useCallback(() => setShowApproval(false), [setShowApproval])

    const onSuccess = useCallback(() => {
        setSucceededId(succeededId + 1)
        setFormErrors([])
        setSearchParams()
        setShowApproval(false)
    }, [setSucceededId, succeededId, setFormErrors, setSearchParams, setShowApproval])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setSucceededId(0)
            setFormErrors(data.nonFieldErrors || ["Er is iets misgegaan"])
        },
        [setSucceededId, setFormErrors]
    )

    useEffect(() => setShowDateNoteView(!!editingDateNote), [editingDateNote])

    if (!editorState) {
        return <ScheduleLoader />
    }

    return (
        <div>
            {mode === "DATERANGE" ? (
                <div className="mb-4">
                    <span className="h2">Rooster</span>
                </div>
            ) : null}
            {mode === "DAYS" ? <ShiftTemplateEditorHead /> : null}

            <div className="flex-bar mb-3">
                <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}>
                    {mode === "DATERANGE" ? <ScheduleDateSelector /> : <ScheduleDaySelector />}
                    <ScheduleLoader />
                </div>
                <div className="filter-panel">
                    {isAdmin ? (
                        <Dropdown className="d-inline-block">
                            <Dropdown.Toggle variant="link" id="action-dropdown-toggle">
                                Acties
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={openBulkPlanner}>Bulkwijziging maken</Dropdown.Item>
                                <Dropdown.Item onClick={openBreakPlanner}>Pauzes inplannen</Dropdown.Item>
                                {mode === "DATERANGE" ? <Dropdown.Item onClick={openDateNoteView}>Dagnotitie maken</Dropdown.Item> : null}
                                {mode === "DATERANGE" ? <Dropdown.Item onClick={openNotificationsView}>Wijzigingen notificeren</Dropdown.Item> : null}
                                {mode === "DAYS" ? <Dropdown.Item onClick={openCopyModal}>Dag kopiëren</Dropdown.Item> : null}
                                {mode === "DAYS" ? <Dropdown.Item onClick={openShiftGenerator}>Automatisch aanvullen</Dropdown.Item> : null}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : null}
                    <ScheduleFilters checkedFreeRole={mode === "DAYS" ? true : false} />
                </div>
            </div>

            {isAdmin ? <ShiftPlanner visible={showBulkPlanner} mode={mode} close={closeBulkPlanner} /> : null}
            {isAdmin ? <BreakPlanner visible={showBreakPlanner} mode={mode} close={closeBreakPlanner} /> : null}
            {mode === "DATERANGE" && isAdmin ? <AbsencePlanner visible={showAbsencePlanner} close={closeAbsencePlanner} /> : null}
            {mode === "DATERANGE" && isAdmin ? <ScheduleApproval visible={showApproval} absenceRequest={absenceRequest} close={closeApproval} onSuccess={onSuccess} onFailure={onFailure} /> : null}
            {mode === "DATERANGE" && isAdmin ? <EditDateNoteModal visible={showDateNoteView} close={closeDateNoteView} note={editingDateNote} /> : null}
            {mode === "DATERANGE" && isAdmin ? <SendNotificationsView visible={showNotificationsView} close={closeNotificationsView} /> : null}
            {mode === "DAYS" && isAdmin ? <CopyShiftDayModal visible={showCopyModal} close={closeCopyModal} /> : null}
            {mode === "DAYS" && isAdmin ? <ShiftGenerator visible={showShiftGenerator} close={closeShiftGenerator} /> : null}

            {mode === "DATERANGE" ? <ScheduleDateNotes setEditingDateNote={setEditingDateNote} /> : null}
            {mode === "DATERANGE" ? <ScheduleWarnings /> : null}
            {mode === "DAYS" ? <ShiftTemplatePublishMessage /> : null}
            <ScheduleValidationMessages />

            <ShiftsEditor />

            {isAdmin ? (
                <div className="col">
                    {mode === "DATERANGE" ? (
                        <Button type="button" onClick={openApproval} data-cy="saveSchedule">
                            Opslaan
                        </Button>
                    ) : (
                        <ShiftTemplateSaveButtons onSuccess={onSuccess} onFailure={onFailure} />
                    )}
                    <FormErrorMessages errors={formErrors} />
                    <FormSuccessMessage succeededId={succeededId} />
                </div>
            ) : null}
        </div>
    )
}

export default ShiftEditor
