import { FC, useMemo } from "react"
import { useCompanyModules } from "../../contexts/UserSettingsContext"
import { getUserTypeOptions } from "../../helpers/UsersHelper"

const UserTypeOptions: FC = () => {
    const companyModules = useCompanyModules()

    const userTypeOptions = useMemo(() => {
        return getUserTypeOptions(companyModules)
    }, [companyModules])

    return userTypeOptions.map(({ id, name }) => (
        <option key={id} value={id}>
            {name}
        </option>
    ))
}

export default UserTypeOptions
