import { FC, useMemo } from "react"
import { TaskShiftMutationProposalResult, TaskShiftTemplateMutationProposalResult, TaskType } from "../../../../services/Task"
import RolePlanningOption from "./RolePlanningOption"

interface RolePlanningOptionsProps {
    task: TaskType<TaskShiftMutationProposalResult | TaskShiftTemplateMutationProposalResult> | undefined
    applyProposalOption: (user: number, startSlot: number, endSlot: number, role: number | null) => void
}

const RolePlanningOptions: FC<RolePlanningOptionsProps> = ({ task, applyProposalOption }) => {
    const role = useMemo(() => task?.result?.role, [task])

    const options = useMemo(() => {
        const options = task?.result?.options ?? []
        options.sort((a, b) => b.score - a.score)
        return options
    }, [task])

    const done = useMemo(() => task?.done, [task])

    if (!done || role === undefined) {
        return null
    }

    if (options.length === 0) {
        return (
            <p className="text-bold" style={{ paddingLeft: "1rem" }}>
                Geen opties gevonden
            </p>
        )
    }

    return (
        <div className="occupation-details-scroll-container">
            <table style={{ width: "100%" }}>
                <tbody>
                    {options.map((o) => (
                        <RolePlanningOption key={`${task?.id}_${o.user}`} role={role} option={o} applyProposalOption={applyProposalOption} />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default RolePlanningOptions
