import { useCallback, useContext, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { setOptionalError } from "../helpers/FormHelper"
import { resetPassword } from "../services/User"

interface Inputs {
    password1: string
    password2: string
}

const PasswordReset = () => {
    const navigate = useNavigate()
    const params = useParams()
    const isAuthenticated = useIsAuthenticated()
    const { reloadUserSettings } = useContext(UserSettingsContext)
    const {
        register,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "password1", data.new_password1)
            setOptionalError(setError, "password2", data.new_password2)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSuccess = useCallback(() => {
        reloadUserSettings()
    }, [reloadUserSettings])

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ password1, password2 }) => {
            const { uid, token } = params
            resetPassword(uid!, token!, password1, password2).then(onSuccess).catch(onFailure)
        },
        [params, onSuccess, onFailure]
    )

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [isAuthenticated])

    return (
        <FullPageCard>
            <div>
                <h1 className="h2">Nieuw wachtwoord instellen</h1>
                <p>Vul een nieuw wachtwoord in om in te kunnen loggen met Rooster Planner.</p>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>Wachtwoord</Form.Label>
                    <Form.Control type="password" {...register("password1")} isInvalid={!!errors.password1} data-cy="password1" autoFocus />
                    <Form.Control.Feedback type="invalid" data-cy="password1_errors">
                        {errors.password1?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Wachtwoord nogmaals</Form.Label>
                    <Form.Control type="password" {...register("password2")} isInvalid={!!errors.password2} data-cy="password2" />
                    <Form.Control.Feedback type="invalid" data-cy="password2_errors">
                        {errors.password2?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 flex-row">
                    <Button type="submit" data-cy="reset-password">
                        Wachtwoord opslaan
                    </Button>
                    <small className="end">
                        <span className="text-muted">Terug naar </span>
                        <Link to="/inloggen" className="fw-bold">
                            Inloggen
                        </Link>
                    </small>
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default PasswordReset
