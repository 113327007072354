import FullPageCard from "../components/page/FullPageCard"

const SamlDeniedPage = () => {
    return (
        <FullPageCard>
            <div>
                <h1 className="h2">Toegang geweigerd</h1>
                <p className="mb-1">
                    Neem contact op met <a href="mailto:info@llmsolutions.nl">info@llmsolutions.nl</a> als dit probleem zich blijft voordoen.
                </p>
            </div>
        </FullPageCard>
    )
}

export default SamlDeniedPage
