import { FC, useContext, useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import Loader from "../components/Loader"
import TopBar from "../components/TopBar"
import MenuFixed from "../components/menu/MenuFixed"
import MenuSlide from "../components/menu/MenuSlide"
import Tour from "../components/tour/Tour"
import { TourContextProvider } from "../contexts/TourContext"
import { UserSettingsContext, ensureCsrfToken, useOptionalSelectedLocation } from "../contexts/UserSettingsContext"
import { COMPANY_MODULE_ABSENCE_REQUESTS, COMPANY_MODULE_EXTERNAL_HIRES } from "../helpers/Constants"
import ActivateAccountPage from "../pages/ActivateAccountPage"
import Login from "../pages/Login"
import LoginMfa from "../pages/LoginMfa"
import LoginMfaNewPage from "../pages/LoginMfaNew"
import Logout from "../pages/Logout"
import PasswordLost from "../pages/PasswordLost"
import PasswordReset from "../pages/PasswordReset"
import SamlDeniedPage from "../pages/SamlDeniedPage"
import SettingsPage from "../pages/SettingsPage"
import Signup from "../pages/Signup"
import StartAccountPage from "../pages/StartAccountPage"
import AbsenceRequestsPage from "../pages/absenceRequests/AbsenceRequestsPage"
import CreateAbsenceRequestPage from "../pages/absenceRequests/CreateAbsenceRequestPage"
import UpdateAbsenceRequestPage from "../pages/absenceRequests/UpdateAbsenceRequestPage"
import Dashboard from "../pages/dashboard/Dashboard"
import WeekPlanningPage from "../pages/dashboard/weekPlanning/WeekPlanningPage"
import EnvironmentGenerationPage from "../pages/environmentGeneration/EnvironmentGenerationPage"
import GenerateEnvironmentPage from "../pages/environmentGeneration/GenerateEnvironmentPage"
import HireAvailabilitiesPage from "../pages/hireAvailability/HireAvailabilitiesPage"
import HireAvailabilityPage from "../pages/hireAvailability/HiringAvailabilityPage"
import EditMutationPage from "../pages/mutations/EditMutationPage"
import MutationsPage from "../pages/mutations/MutationsPage"
import CreateOccupationTemplatePage from "../pages/occupation/CreateOccupationTemplatePage"
import GenerateOccupationTemplateStatusPage from "../pages/occupation/GenerateOccupationTemplateStatusPage"
import OccupationTemplatesPage from "../pages/occupation/OccupationTemplatesPage"
import UpdateOccupationTemplateDetailsPage from "../pages/occupation/UpdateOccupationTemplateDetailsPage"
import UpdateOccupationTemplatePage from "../pages/occupation/UpdateOccupationTemplatePage"
import OccupationSourcePage from "../pages/occupation/unexus/OccupationSourcePage"
import CreateRoleGroupPage from "../pages/roleGroups/CreateRoleGroupPage"
import RoleGroupsPage from "../pages/roleGroups/RoleGroupsPage"
import UpdateRoleGroupPage from "../pages/roleGroups/UpdateRoleGroupPage"
import CreateRolePage from "../pages/roles/CreateRolePage"
import RolesPage from "../pages/roles/RolesPage"
import UpdateRolePage from "../pages/roles/UpdateRolePage"
import UpdateSchedulePage from "../pages/schedule/UpdateSchedulePage"
import CreateShiftTemplatePage from "../pages/shift/CreateShiftTemplatePage"
import GenerateShiftTemplateStatusPage from "../pages/shift/GenerateShiftTemplateStatusPage"
import ShiftTemplatesPage from "../pages/shift/ShiftTemplatesPage"
import UpdateShiftTemplateDetailsPage from "../pages/shift/UpdateShiftTemplateDetailsPage"
import UpdateShiftTemplatePage from "../pages/shift/UpdateShiftTemplatePage"
import StatisticsPage from "../pages/statistics/StatisticsPage"
import CreateUserPage from "../pages/users/CreateUserPage"
import MyAccountPage from "../pages/users/MyAccountPage"
import MyPasswordPage from "../pages/users/MyPasswordPage"
import UpdateUserPage from "../pages/users/UpdateUserPage"
import UsersPage from "../pages/users/UsersPage"
import AdminElement from "./AdminElement"
import AnonymousElement from "./AnonymousElement"
import InitModeElement from "./InitModeElement"
import PrivateElement from "./PrivateElement"

const MainLayout: FC = () => {
    const { loading, reloadUserSettings } = useContext(UserSettingsContext)
    const location = useOptionalSelectedLocation()

    useEffect(() => {
        ensureCsrfToken()
        reloadUserSettings()
    }, [reloadUserSettings])

    return loading ? (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Loader />
            </div>
        </div>
    ) : (
        <TourContextProvider>
            <div className="full-height-container">
                <main className="container-fluid footer-container">
                    <TopBar />
                    <MenuSlide />
                    <Tour />
                    <div className="d-flex">
                        <MenuFixed />
                        <Routes>
                            <Route
                                path="inloggen"
                                element={
                                    <AnonymousElement>
                                        <Login />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="inloggen/mfa"
                                element={
                                    <AnonymousElement>
                                        <LoginMfa />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="inloggen/mfa/start"
                                element={
                                    <AnonymousElement>
                                        <LoginMfaNewPage />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="registreren"
                                element={
                                    <AnonymousElement>
                                        <Signup />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="start/:uid/:token"
                                element={
                                    <AnonymousElement>
                                        <StartAccountPage initCompany={false} />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="nieuwe-omgeving/:uid/:token"
                                element={
                                    <AnonymousElement>
                                        <StartAccountPage initCompany={true} />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="omgeving-initialiseren"
                                element={
                                    <InitModeElement>
                                        <GenerateEnvironmentPage />
                                    </InitModeElement>
                                }
                            />
                            <Route
                                path="omgeving-initialisatie"
                                element={
                                    <InitModeElement>
                                        <EnvironmentGenerationPage />
                                    </InitModeElement>
                                }
                            />
                            <Route
                                path="wachtwoord-vergeten"
                                element={
                                    <AnonymousElement>
                                        <PasswordLost />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="nieuw-wachtwoord/:uid/:token"
                                element={
                                    <AnonymousElement>
                                        <PasswordReset />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="activeren/:uid/:token"
                                element={
                                    <AnonymousElement>
                                        <ActivateAccountPage />
                                    </AnonymousElement>
                                }
                            />
                            <Route
                                path="saml/geweigerd"
                                element={
                                    <AnonymousElement>
                                        <SamlDeniedPage />
                                    </AnonymousElement>
                                }
                            />

                            <Route
                                path="dashboard"
                                element={
                                    <PrivateElement>
                                        <Dashboard key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="rooster"
                                element={
                                    <PrivateElement>
                                        <UpdateSchedulePage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="weekplanning"
                                element={
                                    <AdminElement>
                                        <WeekPlanningPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="beschikbaarheid"
                                element={
                                    <PrivateElement requiredModule={COMPANY_MODULE_EXTERNAL_HIRES}>
                                        <HireAvailabilitiesPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="beschikbaarheid/:id"
                                element={
                                    <PrivateElement requiredModule={COMPANY_MODULE_EXTERNAL_HIRES}>
                                        <HireAvailabilityPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="verlofaanvragen"
                                element={
                                    <PrivateElement requiredModule={COMPANY_MODULE_ABSENCE_REQUESTS}>
                                        <AbsenceRequestsPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="verlofaanvragen/nieuw"
                                element={
                                    <PrivateElement requiredModule={COMPANY_MODULE_ABSENCE_REQUESTS}>
                                        <CreateAbsenceRequestPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="verlofaanvragen/:id"
                                element={
                                    <PrivateElement requiredModule={COMPANY_MODULE_ABSENCE_REQUESTS}>
                                        <UpdateAbsenceRequestPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="instellingen"
                                element={
                                    <AdminElement>
                                        <SettingsPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="mijn-account"
                                element={
                                    <PrivateElement>
                                        <MyAccountPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="wachtwoord-wijzigen"
                                element={
                                    <PrivateElement>
                                        <MyPasswordPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />

                            <Route
                                path="wijzigingen"
                                element={
                                    <PrivateElement>
                                        <MutationsPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="wijzigingen/:id"
                                element={
                                    <PrivateElement>
                                        <EditMutationPage key={location?.id} />
                                    </PrivateElement>
                                }
                            />
                            <Route
                                path="statistieken"
                                element={
                                    <AdminElement>
                                        <StatisticsPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="taakgroepen"
                                element={
                                    <AdminElement>
                                        <RoleGroupsPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="taakgroepen/nieuw"
                                element={
                                    <AdminElement>
                                        <CreateRoleGroupPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="taakgroepen/:id"
                                element={
                                    <AdminElement>
                                        <UpdateRoleGroupPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="taken"
                                element={
                                    <AdminElement>
                                        <RolesPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="taken/nieuw"
                                element={
                                    <AdminElement>
                                        <CreateRolePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="taken/:id"
                                element={
                                    <AdminElement>
                                        <UpdateRolePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisbezettingen"
                                element={
                                    <AdminElement>
                                        <OccupationTemplatesPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisbezettingen/nieuw"
                                element={
                                    <AdminElement>
                                        <CreateOccupationTemplatePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisbezettingen/:id"
                                element={
                                    <AdminElement>
                                        <UpdateOccupationTemplatePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisbezettingen/:id/details"
                                element={
                                    <AdminElement>
                                        <UpdateOccupationTemplateDetailsPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisbezettingen/:occupationTemplateId/genereer/:occupationTemplateGenerationId"
                                element={
                                    <AdminElement>
                                        <GenerateOccupationTemplateStatusPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisbezettingen/:template/bezettingsbronnen/:id/"
                                element={
                                    <AdminElement>
                                        <OccupationSourcePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisroosters"
                                element={
                                    <AdminElement>
                                        <ShiftTemplatesPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisroosters/nieuw"
                                element={
                                    <AdminElement>
                                        <CreateShiftTemplatePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisroosters/:id"
                                element={
                                    <AdminElement>
                                        <UpdateShiftTemplatePage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisroosters/:id/details"
                                element={
                                    <AdminElement>
                                        <UpdateShiftTemplateDetailsPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="basisroosters/:shiftTemplateId/genereer/:taskId"
                                element={
                                    <AdminElement>
                                        <GenerateShiftTemplateStatusPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="medewerkers"
                                element={
                                    <AdminElement>
                                        <UsersPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="medewerkers/nieuw"
                                element={
                                    <AdminElement>
                                        <CreateUserPage key={location?.id} />
                                    </AdminElement>
                                }
                            />
                            <Route
                                path="medewerkers/:id"
                                element={
                                    <AdminElement>
                                        <UpdateUserPage key={location?.id} />
                                    </AdminElement>
                                }
                            />

                            <Route path="uitloggen" element={<Logout />} />
                            <Route path="*" element={<Navigate to="dashboard" />} />
                        </Routes>
                    </div>
                </main>
                <div className="footer text-muted text-small">
                    <a href="https://rooster-planner.nl/over-ons/" target="_blank" rel="noreferrer">
                        &copy; LLM Solutions
                    </a>
                </div>
            </div>
        </TourContextProvider>
    )
}

export default MainLayout
