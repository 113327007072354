export const ROLE_TYPE_REGULAR = "regular"
export const ROLE_TYPE_LEAVE = "leave"
export const ROLE_TYPE_SICK = "sick"
export const ROLE_TYPE_BREAK = "break"

export type ROLE_TYPE = "regular" | "leave" | "sick" | "break"

export const COMPANY_MODULE_ABSENCE_REQUESTS = "ABSENCE_REQUESTS"
export const COMPANY_MODULE_REMOTE_WORKING = "REMOTE_WORKING"
export const COMPANY_MODULE_EXTERNAL_HIRES = "EXTERNAL_HIRES"
export const COMPANY_MODULE_NOTIFICATIONS = "NOTIFICATIONS"
export const COMPANY_MODULE_SSO = "SSO"
export const COMPANY_MODULE_UNEXUS = "UNEXUS"

export const HOLIDAY_TYPE_DATE = "DATE"
export const HOLIDAY_TYPE_DAY = "DAY"
export const HOLIDAY_TYPE_ONCE = "ONCE"
