import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties, FC, useMemo } from "react"
import { Button } from "react-bootstrap"
import { useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { getPopoverStyle } from "../../../helpers/editor/OccupationDetailsHelper"
import OccupationTimeSlotDetails from "./OccupationTimeSlotDetails"

interface OccupationTimeSlotPopoverProps {
    selectedSlot: number | undefined
    occupationData: Map<string, (number | null)[][]>
    setPlanningRole: (planningRole: number | undefined) => void
    popoverFaded: boolean
    togglePopoverFaded: () => void
    onClosePopover: () => void
}

const OccupationTimeSlotPopover: FC<OccupationTimeSlotPopoverProps> = ({ selectedSlot, occupationData, setPlanningRole, popoverFaded, togglePopoverFaded, onClosePopover }) => {
    const editorState = useScheduleEditorState()
    const { location } = editorState
    const { startSlot, endSlot } = location

    const popoverStyle: CSSProperties = useMemo(() => getPopoverStyle(selectedSlot, startSlot, endSlot, popoverFaded), [selectedSlot, startSlot, endSlot, popoverFaded])

    return (
        <div className="rp-tooltip occupation-details-popover" style={popoverStyle}>
            <div className="occupation-details-header">
                <div className="occupation-details-popover-title">
                    <span className="text-bold popover-title">Bezetting</span>
                </div>
                <Button type="button" variant="link" onClick={togglePopoverFaded} className="px-2">
                    <FontAwesomeIcon icon={popoverFaded ? faEye : faEyeSlash} />
                </Button>
                <Button type="button" variant="link" onClick={onClosePopover} className="px-2">
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </div>
            <div className="occupation-details-scroll-container">
                {selectedSlot !== undefined ? <OccupationTimeSlotDetails selectedSlotIndex={selectedSlot - startSlot} occupationData={occupationData} setPlanningRole={setPlanningRole} /> : null}
            </div>
        </div>
    )
}

export default OccupationTimeSlotPopover
