import { useCallback, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import FormSuccessMessage from "../components/form/FormSuccessMessage"
import FullPageCard from "../components/page/FullPageCard"
import { setOptionalError } from "../helpers/FormHelper"
import { passwordLost } from "../services/User"

interface Inputs {
    email: string
}

const PasswordLost = () => {
    const [succeededId, setSucceededId] = useState(0)
    const {
        register,
        reset,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onSuccess = useCallback(() => {
        reset()
        setSucceededId(succeededId + 1)
    }, [reset, setSucceededId])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "email", data.email)
            setOptionalError(setError, "root", data.nonFieldErrors)
            setSucceededId(0)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ email }) => {
            passwordLost(email).then(onSuccess).catch(onFailure)
        },
        [onSuccess, onFailure]
    )

    return (
        <FullPageCard>
            <div>
                <h1 className="h2">Wachtwoord vergeten</h1>
                <p>Vul je e-mailadres in om je wachtwoord te herstellen.</p>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>E-mailadres</Form.Label>
                    <Form.Control type="email" autoComplete="email" {...register("email")} isInvalid={!!errors.email} data-cy="email" autoFocus />
                    <Form.Control.Feedback type="invalid" data-cy="email_errors">
                        {errors.email?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 flex-row">
                    <Button type="submit" data-cy="reset-password">
                        Herstel wachtwoord
                    </Button>
                    <small className="end">
                        <span className="text-muted">Terug naar </span>
                        <Link to="/inloggen" className="fw-bold">
                            Inloggen
                        </Link>
                    </small>
                </Form.Group>
                <Form.Group>
                    <FormSuccessMessage succeededId={succeededId} />
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default PasswordLost
