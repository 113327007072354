import { ColumnDef, PaginationState, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { FC, useMemo, useState } from "react"
import { ButtonVariant } from "react-bootstrap/esm/types"
import { useQuery } from "react-query"
import { useGetInitialValues } from "../../hooks/UseCache"
import PageRequestType from "../../types/PageRequestType"
import PageResponseType from "../../types/PageResponseType"
import TableButtons from "./TableButtons"
import TableFilters, { FilterSelectOption } from "./TableFilters"
import Pagination from "./TablePagination"

export interface TableButtonDef {
    name: string
    link?: string
    onClick?: () => void
    variant?: ButtonVariant
    dataCy?: string
}

export interface TableFilterValue {
    key: string
    value: string
}

export interface TableFilterDef {
    id: string
    label?: string
    placeholder?: string
    type: "toggle" | "date" | "daterange" | "hidden" | "select" | "picker"
    initialValues: TableFilterValue[]
    options?: FilterSelectOption[]
}

interface Props<Type> {
    id: string
    columns: ColumnDef<Type, any>[]
    buttons?: TableButtonDef[]
    filters: TableFilterDef[]
    fetchData: (request: PageRequestType) => Promise<PageResponseType<Type>>
    subject: string // Used to show when no "items" are in table, e.g: "users" or "roles".
    minWidth: string
}

const Table: FC<Props<any>> = ({ id, columns, buttons, filters, fetchData, subject, minWidth }) => {
    const getInitialValues = useGetInitialValues()
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 })
    const [filterValues, setFilterValues] = useState<TableFilterValue[]>(getInitialValues(id, filters))
    const fetchDataOptions = { pageIndex, pageSize, filters: filterValues }
    const dataQuery = useQuery(["data", id, fetchDataOptions], () => fetchData(fetchDataOptions), { keepPreviousData: true, refetchOnWindowFocus: false })
    const defaultData = useMemo(() => [], [])
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize])

    const table = useReactTable({
        data: dataQuery.data?.items ?? defaultData,
        columns,
        pageCount: dataQuery.data?.pageCount ?? -1,
        state: {
            pagination,
            globalFilter: filterValues,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
    })

    return (
        <div>
            <div className="table-button-row">
                <TableButtons buttons={buttons} />
                <TableFilters contextId={id} filters={filters} filterValues={filterValues} setFilterValues={setFilterValues} />
            </div>
            <div className="container-scroll">
                <table className="table mt-4" style={{ minWidth: minWidth }}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getAllCells().map((cell) => {
                                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                    })}
                                </tr>
                            )
                        })}
                        {table.getRowModel().rows.length === 0 ? (
                            <tr>
                                <td className="text-muted text-center" colSpan={columns.length}>
                                    Geen {subject}
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>
            <Pagination pagination={pagination} pageCount={table.getPageCount()} goToPage={table.setPageIndex} setPageSize={table.setPageSize} />
        </div>
    )
}

export default Table
