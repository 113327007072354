import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import CustomHolidayArray from "../components/form/CustomHolidayArray"
import FormErrorMessages from "../components/form/FormErrorMessages"
import FormSuccessMessage from "../components/form/FormSuccessMessage"
import NationalHolidayArray from "../components/form/NationalHolidayArray"
import RegularPage from "../components/page/RegularPage"
import { useTabs } from "../contexts/TabsContext"
import { useSelectedLocation } from "../contexts/UserSettingsContext"
import { getShortDayName, getTimeString } from "../helpers/DaysHelper"
import { loadHolidaySettings, updateHolidaySettings } from "../services/HolidaySettings"
import { HolidaySettingsType } from "../types/HolidaySettingsType"

const SettingsPage: FC = () => {
    const queryClient = useQueryClient()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const [succeededId, setSucceededId] = useState(0)
    const [formErrors, setFormErrors] = useState<string[]>([])

    useEffect(() => setActiveTab("Settings"), [setActiveTab])

    const { data: holidaySettings } = useQuery(["HolidaySetting", location.id], () => loadHolidaySettings(location.id))

    const { control, register, handleSubmit, getValues, setValue, watch } = useForm<HolidaySettingsType>()

    useEffect(() => {
        if (holidaySettings) {
            const { customHolidays, nationalHolidays } = holidaySettings
            setValue("customHolidays", customHolidays)
            setValue("nationalHolidays", nationalHolidays)
        }
    }, [holidaySettings])

    const removeQueries = useCallback(() => {
        queryClient.removeQueries({ queryKey: ["HolidaySetting", location.id] })
    }, [queryClient, location])

    const onSuccess = useCallback(() => {
        setSucceededId(succeededId + 1)
        setFormErrors([])
        removeQueries()
    }, [setSucceededId, succeededId, setFormErrors, removeQueries])

    const onFailure = useCallback(() => {
        setFormErrors(["Er is iets misgegaan"])
        setSucceededId(0)
    }, [setSucceededId, setFormErrors])

    const onSubmit = useCallback(
        (data: HolidaySettingsType) => {
            updateHolidaySettings(data, location.id).then(onSuccess).catch(onFailure)
        },
        [location, onSuccess, onFailure]
    )

    const daysValue = useMemo(
        () =>
            Array.from(location.enabledDays)
                .map((c, i) => (c === "1" ? `${getShortDayName(i).toLowerCase()}` : null))
                .filter((c) => !!c)
                .join(", "),
        [location]
    )

    const timeLineValue = useMemo(() => `${getTimeString("start", location.startSlot)}-${getTimeString("end", location.endSlot)}`, [location])

    return (
        <RegularPage id="MyPassword">
            <div className="mb-4">
                <h1 className="h2">Instellingen</h1>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                <Form.Group className="mb-3">
                    <Form.Label>Naam</Form.Label>
                    <Form.Control size="lg" type="text" value={location.name} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Dagen</Form.Label>
                    <Form.Control size="lg" type="text" value={daysValue} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Tijdlijn</Form.Label>
                    <Form.Control size="lg" type="text" value={timeLineValue} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Feestdagen</Form.Label>
                    <div className="ms-2">
                        <div className="mb-4">
                            <NationalHolidayArray control={control} register={register} />
                        </div>
                        <div>
                            <CustomHolidayArray control={control} register={register} setValue={setValue} getValues={getValues} watch={watch} />
                        </div>
                    </div>
                </Form.Group>
                <Button type="submit" data-cy="submit-button">
                    Opslaan
                </Button>
                <FormErrorMessages errors={formErrors} className="mt-3" />
                <FormSuccessMessage succeededId={succeededId} className="mt-3" />
            </Form>
        </RegularPage>
    )
}

export default SettingsPage
