import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { useColors } from "../../../contexts/UserSettingsContext"
import { getCSSColor } from "../../../helpers/ColorHelper"
import { NestedRoleItem } from "../../../helpers/RolesHelper"
import subIcon from "../../../images/sub-icon.svg"
import OccupationBadge from "./OccupationBadge"

interface Props {
    roleItem: NestedRoleItem
    min: number | null
    max: number | null
    actual: number
    setPlanningRole: (planningRole: number) => void
}

const OccupationTimeSlotDetailRow: FC<Props> = ({ roleItem, min, max, actual, setPlanningRole }) => {
    const colors = useColors()

    const roleColor = useMemo(() => getCSSColor(colors.get(roleItem.color)!), [colors, roleItem])

    return (
        <tr
            className={"occupation-details-row" + (roleItem.type === "roleGroup" ? " occupation-details-row-disabled" : "")}
            onClick={roleItem.type === "roleGroup" ? undefined : () => setPlanningRole(roleItem.id)}
        >
            <td>
                <FontAwesomeIcon icon={faCircle} style={{ color: roleColor }} />
            </td>
            <td className="occupation-time-slot-detail text-bold">
                {roleItem.parent ? <img className="custom-fa-icon me-1" src={subIcon} alt="sub-arrow" /> : null}
                {roleItem.name}
            </td>
            <td className="occupation-time-slot-detail text-bold ps-4 text-end">
                <OccupationBadge min={min} max={max} actual={actual} />
            </td>
        </tr>
    )
}

export default OccupationTimeSlotDetailRow
