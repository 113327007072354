import { FC, useCallback, useMemo } from "react"
import { UseFormClearErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { UserEditInputs } from "../../../pages/users/UserEditPage"
import InlineGroupLabel from "../../form/InlineGroupLabel"
import UserTimeInputPopover from "./UserTimeInputPopover"

interface UserTimeInputProps {
    disabled: boolean
    day: number
    startSlot: number
    endSlot: number
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    clearErrors: UseFormClearErrors<UserEditInputs>
}

const UserTimeInput: FC<UserTimeInputProps> = ({ disabled, day, startSlot, endSlot, watch, setValue, clearErrors }) => {
    const availabilities = watch("availabilities")
    const availability = useMemo(() => availabilities.find((a) => a.day === day), [availabilities, day])

    const updateAvailability = useCallback(
        (startTime?: string, endTime?: string) => {
            const newAvailabilities = [...availabilities]

            if (!availability) {
                newAvailabilities.push({
                    day,
                    startTime,
                    endTime,
                })
            } else {
                availability.startTime = startTime
                availability.endTime = endTime
            }

            const indexToRemove = newAvailabilities.findIndex((a) => a.startTime === undefined && a.endTime === undefined)
            if (indexToRemove !== -1) {
                newAvailabilities.splice(indexToRemove, 1)
            }

            setValue("availabilities", newAvailabilities)
            clearErrors("availabilities")
        },
        [availabilities, availability, day, setValue, clearErrors]
    )

    const setStartTime = useCallback((newValue: string) => updateAvailability(newValue === "" ? undefined : newValue, availability?.endTime), [availability, updateAvailability])

    const setEndTime = useCallback((newValue: string) => updateAvailability(availability?.startTime, newValue === "" ? undefined : newValue), [availability, updateAvailability])

    const selectedStartTime = useMemo(() => {
        return availability?.startTime ? availability.startTime : ""
    }, [availability])

    const selectedEndTime = useMemo(() => {
        return availability?.endTime ? availability.endTime : ""
    }, [availability])

    return (
        <div className="input-group">
            <InlineGroupLabel inline={false}>Van</InlineGroupLabel>
            <UserTimeInputPopover startSlot={startSlot} endSlot={endSlot} selectedTime={selectedStartTime} setSelectedTime={setStartTime} type="start" disabled={disabled} />
            <InlineGroupLabel inline={false}>tot</InlineGroupLabel>
            <UserTimeInputPopover startSlot={startSlot} endSlot={endSlot} selectedTime={selectedEndTime} setSelectedTime={setEndTime} type="end" disabled={disabled} />
        </div>
    )
}

export default UserTimeInput
