import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import DateRangeCell from "../../components/table/cells/DateRangeCell"
import DateSlotCell from "../../components/table/cells/DateSlotCell"
import IconCell from "../../components/table/cells/IconCell"
import UserNameCell from "../../components/table/cells/UserNameCell"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_ABSENCE_REQUEST } from "../../contexts/TourSteps"
import { useIsAdmin, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { getHandleStatusIcon, getReviewStatusIcon, handleStatusOptions, reviewStatusOptions } from "../../helpers/AbsenceRequestHelper"
import * as DaysHelper from "../../helpers/DaysHelper"
import { dateFromDjango } from "../../helpers/DaysHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadAbsenceRequests } from "../../services/AbsenceRequest"
import AbsenceRequestType from "../../types/AbsenceRequestType"

const AbsenceRequestsPage: FC = () => {
    const { setActiveTab } = useTabs()
    const isAdmin = useIsAdmin()
    const location = useSelectedLocation()
    const users = useUsersForSelectedLocation()
    const resolveUserName = useResolveUserName(users)
    const continueTour = useContinueTour()

    const columns = useMemo((): ColumnDef<AbsenceRequestType>[] => {
        const columns: ColumnDef<AbsenceRequestType>[] = []
        if (isAdmin) {
            columns.push({
                id: "user",
                header: "Medewerker",
                cell: ({ row }) => <UserNameCell userName={resolveUserName(row.original.user)} link={`/verlofaanvragen/${row.original.id}`} />,
            })
            columns.push({
                id: "from",
                header: "Van",
                cell: ({ row }) => <DateSlotCell date={dateFromDjango(row.original.startDate)} slot={row.original.startSlot} at="start" />,
            })
            columns.push({
                id: "to",
                header: "Tot",
                cell: ({ row }) => <DateSlotCell date={dateFromDjango(row.original.endDate)} slot={row.original.endSlot} at="end" />,
            })
        } else {
            columns.push({
                id: "fromTo",
                header: "Periode",
                cell: ({ row }) => <DateRangeCell from={row.original.startDate} to={row.original.endDate} link={`/verlofaanvragen/${row.original.id}`} />,
            })
        }
        columns.push({
            id: "reviewStatus",
            header: "Beoordeling",
            cell: ({ row }) => <IconCell iconProps={getReviewStatusIcon(row.original.reviewStatus)} />,
        })
        columns.push({
            id: "handleStatus",
            header: "Afhandeling",
            cell: ({ row }) => <IconCell iconProps={getHandleStatusIcon(row.original.handleStatus)} />,
        })
        return columns
    }, [isAdmin, resolveUserName])

    useEffect(() => continueTour(STEP_INDEX_ABSENCE_REQUEST), [continueTour])
    useEffect(() => setActiveTab("AbsenceRequests"), [setActiveTab])

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="AbsenceRequests">
            <div className="mb-4">
                <h1 className="h2">Verlofaanvragen</h1>
            </div>
            <Table
                id={`${location.id}_AbsenceRequests`}
                columns={columns}
                buttons={[{ name: "Nieuwe verlofaanvraag", link: `/verlofaanvragen/nieuw` }]}
                filters={[
                    locationFilter,
                    {
                        id: "date",
                        type: "daterange",
                        label: "Periode:",
                        initialValues: [
                            { key: "dateFrom", value: DaysHelper.isoPrint(new Date()) },
                            { key: "dateTo", value: "" },
                        ],
                    },
                    {
                        id: "user",
                        type: isAdmin ? "picker" : "hidden",
                        label: "Medewerker:",
                        placeholder: "Naam...",
                        options: users.map((user) => {
                            return { key: user.id.toString(), title: `${user.firstName} ${user.lastName}` }
                        }),
                        initialValues: [{ key: "user", value: "" }],
                    },
                    {
                        id: "reviewStatus",
                        type: isAdmin ? "picker" : "hidden",
                        label: "Beoordeling:",
                        placeholder: "Beoordeling...",
                        options: reviewStatusOptions.map(({ id, name }) => {
                            return { key: id, title: name }
                        }),
                        initialValues: [{ key: "reviewStatus", value: "" }],
                    },
                    {
                        id: "handleStatus",
                        type: isAdmin ? "picker" : "hidden",
                        label: "Afhandeling:",
                        placeholder: "Afhandeling...",
                        options: handleStatusOptions.map(({ id, name }) => {
                            return { key: id, title: name }
                        }),
                        initialValues: [{ key: "handleStatus", value: "" }],
                    },
                ]}
                fetchData={loadAbsenceRequests}
                subject="verlofaanvragen"
                minWidth="700px"
            />
        </RegularPage>
    )
}

export default AbsenceRequestsPage
