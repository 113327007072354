import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { addDays, getFirstPreviousWorkday } from "../../helpers/DaysHelper"

interface Props {
    mode: "PREVIOUS" | "NEXT"
    selectedDate: Date
    setSelectedDate: (selectedDate: Date) => void
    enabledDays: string
}

const WeekControl: FC<Props> = ({ mode, selectedDate, setSelectedDate, enabledDays }) => {
    const onClick = useCallback(() => {
        setSelectedDate(getFirstPreviousWorkday(addDays(selectedDate, mode === "NEXT" ? 7 : -7), enabledDays))
    }, [setSelectedDate, selectedDate, enabledDays])

    return (
        <Button variant="link" size="sm" className={mode === "NEXT" ? "ps-1" : "pe-1"} data-cy={mode === "NEXT" ? "nextWeek" : "previousWeek"} onClick={onClick}>
            <FontAwesomeIcon icon={mode === "NEXT" ? faCaretRight : faCaretLeft} />
        </Button>
    )
}

export default WeekControl
