import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { logout } from "../services/User"

const Logout = () => {
    const isAuthenticated = useIsAuthenticated()
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)

    useEffect(() => {
        logout().then(reloadUserSettings)
    }, [reloadUserSettings])

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/inloggen")
        }
    }, [isAuthenticated])

    return null
}

export default Logout
