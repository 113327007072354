import { FC, useMemo } from "react"
import { useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { getCountForTotal, isEnabled } from "../../../helpers/OccupationHelper"
import { NestedRoleItem } from "../../../helpers/RolesHelper"
import OccupationTimeSlotDetailRow from "./OccupationTimeSlotDetailRow"

interface OccupationTimeSlotDetailsProps {
    selectedSlotIndex: number | undefined
    occupationData: Map<string, (number | null)[][]>
    setPlanningRole: (planningRole: number | undefined) => void
}

const OccupationTimeSlotDetails: FC<OccupationTimeSlotDetailsProps> = ({ selectedSlotIndex, occupationData, setPlanningRole }) => {
    const editorState = useScheduleEditorState()
    const { nestedRoles, enabledRoles } = editorState

    const total = useMemo(() => {
        let total = 0
        if (occupationData === undefined || selectedSlotIndex === undefined) {
            return total
        }

        for (const roleItem of nestedRoles) {
            total += getCountForTotal(roleItem, enabledRoles, occupationData, selectedSlotIndex!)
            if (roleItem.subRoles) {
                for (const subItem of roleItem.subRoles) {
                    total += getCountForTotal(subItem, enabledRoles, occupationData, selectedSlotIndex!)
                }
            }
        }
        return total
    }, [nestedRoles])

    interface Row {
        roleItem: NestedRoleItem
        min: number | null
        max: number | null
        actual: number
    }

    const rows: Row[] = useMemo(() => {
        const rows: Row[] = []
        if (occupationData === undefined || selectedSlotIndex === undefined) {
            return rows
        }

        for (const roleItem of nestedRoles) {
            if (isEnabled(roleItem, enabledRoles)) {
                const values = occupationData.get(roleItem.uid)?.at(selectedSlotIndex)
                const min = values?.at(0) ?? null
                const max = values?.at(1) ?? null
                const actual = values?.at(2) ?? 0
                rows.push({ roleItem, min, max, actual })
            }

            if (roleItem.subRoles) {
                for (const subItem of roleItem.subRoles) {
                    if (isEnabled(subItem, enabledRoles)) {
                        const values = occupationData.get(subItem.uid)?.at(selectedSlotIndex)
                        const min = values?.at(0) ?? null
                        const max = values?.at(1) ?? null
                        const actual = values?.at(2) ?? 0
                        rows.push({ roleItem: subItem, min, max, actual })
                    }
                }
            }
        }

        return rows
    }, [nestedRoles])

    if (selectedSlotIndex === undefined) {
        return null
    }

    return (
        <table>
            <thead>
                <tr key="-1">
                    <td />
                    <td className="occupation-time-slot-detail text-extra-bold">Totaal</td>
                    <td className="occupation-time-slot-detail text-extra-bold pe-4 text-end">{total}</td>
                </tr>
            </thead>
            <tbody>
                {rows.map(({ roleItem, min, max, actual }) => (
                    <OccupationTimeSlotDetailRow key={roleItem.uid + "-" + min + "-" + max + "-" + actual} roleItem={roleItem} min={min} max={max} actual={actual} setPlanningRole={setPlanningRole} />
                ))}
            </tbody>
        </table>
    )
}

export default OccupationTimeSlotDetails
