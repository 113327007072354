import { CSSProperties } from "react"

export function getPopoverStyle(selectedSlot: number | undefined, startSlot: number, endSlot: number, popoverFaded: boolean) {
    let style: CSSProperties = { display: selectedSlot !== undefined ? "block" : "none" }

    let position = selectedSlot !== undefined ? (selectedSlot - startSlot) / (endSlot - startSlot) : 0
    if (position < 0.5) {
        style["left"] = Math.floor(position * 100) + "%"
    } else {
        style["right"] = Math.ceil((1.0 - position) * 50) + "%"
    }
    if (popoverFaded) {
        style["opacity"] = "0.4"
    }
    return style
}
