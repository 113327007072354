import { FC, useCallback, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import ShiftEditor from "../../components/shiftEditor/ShiftEditor"
import { ScheduleEditorContextProvider } from "../../contexts/ScheduleEditorContext"
import { useTabs } from "../../contexts/TabsContext"
import { useCompanyModules, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { COMPANY_MODULE_EXTERNAL_HIRES } from "../../helpers/Constants"
import { loadNotesForDate } from "../../helpers/DateNoteHelper"
import * as DaysHelper from "../../helpers/DaysHelper"
import { loadHiringAvailabilitiesForDate } from "../../helpers/HiringAvailabilityHelper"
import { loadOccupationsForSchedule } from "../../helpers/OccupationHelper"
import { getOptionalDateParam } from "../../helpers/ParamHelper"
import { ScheduleMap } from "../../helpers/ScheduleMapHelper"
import { loadScheduleForDate } from "../../helpers/ShiftHelper"
import { createTaskShiftMutationProposal, loadTask, TaskShiftMutationProposalResult, TaskType } from "../../services/Task"
import LocationType from "../../types/LocationType"
import ShiftTemplateType from "../../types/ShiftTemplateType"

const UpdateSchedulePage: FC = () => {
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const [searchParams] = useSearchParams()
    const companyModules = useCompanyModules()
    const initialDate = useMemo(() => getOptionalDateParam("date", searchParams) ?? DaysHelper.getFirstNextWorkday(new Date(), location.enabledDays), [searchParams, location])

    const loadSchedule = useCallback((_: ShiftTemplateType | null, location: LocationType | null, date: Date | null) => loadScheduleForDate(location!, date!), [])
    const loadOccupations = useCallback((_: ShiftTemplateType | null, location: LocationType | null, date: Date | null) => loadOccupationsForSchedule(location!, date!), [])
    const loadHiringAvailabilities = useMemo(() => {
        return companyModules.includes(COMPANY_MODULE_EXTERNAL_HIRES) ? loadHiringAvailabilitiesForDate : undefined
    }, [companyModules])
    const loadProposal = useCallback((id: number) => loadTask<TaskShiftMutationProposalResult>(id), [])
    const generateProposal = useCallback((shiftTemplate: ShiftTemplateType | null, day: number | null, date: Date | null, slot: number, role: number, newShiftMutations: ScheduleMap) => {
        return new Promise<TaskType<TaskShiftMutationProposalResult>>((resolve) => {
            createTaskShiftMutationProposal({
                role,
                date: date!,
                location: location.id,
                slot,
                newShiftMutations,
            }).then((response) => resolve(response.data))
        })
    }, [])

    useEffect(() => setActiveTab("Schedule"), [setActiveTab])

    return (
        <RegularPage id="UpdateSchedule">
            <ScheduleEditorContextProvider
                initialLocation={location}
                initialDate={initialDate}
                loadSchedule={loadSchedule}
                loadOccupations={loadOccupations}
                loadNotes={loadNotesForDate}
                loadHiringAvailabilities={loadHiringAvailabilities}
                generateProposal={generateProposal}
                loadProposal={loadProposal}
            >
                <ShiftEditor mode="DATERANGE" />
            </ScheduleEditorContextProvider>
        </RegularPage>
    )
}

export default UpdateSchedulePage
