import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"

const ShiftTemplateEditorHead = () => {
    const editorState = useScheduleEditorState()

    const { id, name } = useMemo(() => {
        return {
            id: editorState.shiftTemplate?.id ?? 0,
            name: editorState.shiftTemplate?.name ?? "",
        }
    }, [editorState.shiftTemplate])

    return (
        <div className="mb-4">
            <h1 className="h2 d-inline-block">
                {name}
                <span className="ms-3 title-button">
                    <Link to={`/basisroosters/${id}/details`}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                </span>
            </h1>
        </div>
    )
}

export default ShiftTemplateEditorHead
