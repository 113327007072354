import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_OCCUPATION_TEMPLATES } from "../../contexts/TourSteps"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getLocationFilter } from "../../helpers/LocationHelper"
import { loadOccupationTemplates } from "../../services/OccupationTemplate"
import OccupationTemplateType from "../../types/OccupationTemplateType"

const OccupationTemplatesPage: FC = () => {
    const location = useSelectedLocation()
    const { setActiveTab } = useTabs()
    const continueTour = useContinueTour()

    useEffect(() => continueTour(STEP_INDEX_OCCUPATION_TEMPLATES), [continueTour])
    useEffect(() => setActiveTab("OccupationTemplates"), [setActiveTab])

    const columns = useMemo(
        (): ColumnDef<OccupationTemplateType>[] => [
            {
                id: "name",
                header: "Naam",
                cell: ({ row }) => <Link to={`/basisbezettingen/${row.original.id}`}>{row.original.name}</Link>,
            },
        ],
        [location]
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="OccupationTemplates">
            <div className="mb-4">
                <h1 className="h2">Basisbezettingen</h1>
            </div>
            <Table
                id={`${location.id}_OccupationTemplates`}
                key={location.id}
                columns={columns}
                buttons={[{ name: "Nieuwe basisbezetting", link: `/basisbezettingen/nieuw`, dataCy: "newOccupationTemplate" }]}
                fetchData={loadOccupationTemplates}
                filters={[locationFilter]}
                subject="basisbezettingen"
                minWidth="100px"
            />
        </RegularPage>
    )
}

export default OccupationTemplatesPage
