import { FC } from "react"

interface TablePaginationProps {
    pagination: {
        pageIndex: number
        pageSize: number
    }
    pageCount: number
    goToPage: (pageIndex: number) => void
    setPageSize: (pageSize: number) => void
}

const TablePagination: FC<TablePaginationProps> = ({ pagination, pageCount, goToPage, setPageSize }) => {
    const { pageIndex, pageSize } = pagination

    const getPaginationButton = (pageIndex: number) => {
        return (
            <button key={pageIndex} type="button" className="btn btn-light btn-rounded" onClick={() => goToPage(pageIndex)}>
                {pageIndex + 1}
            </button>
        )
    }
    const getFirstPaginationButton = (pageIndex: number) => {
        return (
            <button key={pageIndex} type="button" className="btn btn-light btn-rounded" onClick={() => goToPage(pageIndex)}>
                <i className="fa-solid fa-angles-left"></i>
            </button>
        )
    }
    const getCurrentPaginationButton = (pageIndex: number) => {
        return (
            <button key={pageIndex} type="button" className="btn btn-primary btn-rounded" onClick={() => goToPage(pageIndex)}>
                {pageIndex + 1}
            </button>
        )
    }
    const getLastPaginationButton = (pageIndex: number) => {
        return (
            <button key={pageIndex} type="button" className="btn btn-light btn-rounded" onClick={() => goToPage(pageIndex)}>
                <i className="fa-solid fa-angles-right"></i>
            </button>
        )
    }

    let buttons = []
    if (pageIndex === 4) {
        buttons.push(getPaginationButton(pageIndex - 4))
    } else if (pageIndex > 4) {
        buttons.push(getFirstPaginationButton(0))
    }
    if (pageIndex > 2) {
        buttons.push(getPaginationButton(pageIndex - 3))
    }
    if (pageIndex > 1) {
        buttons.push(getPaginationButton(pageIndex - 2))
    }
    if (pageIndex > 0) {
        buttons.push(getPaginationButton(pageIndex - 1))
    }
    buttons.push(getCurrentPaginationButton(pageIndex))
    if (pageIndex + 1 < pageCount) {
        buttons.push(getPaginationButton(pageIndex + 1))
    }
    if (pageIndex + 2 < pageCount) {
        buttons.push(getPaginationButton(pageIndex + 2))
    }
    if (pageIndex + 3 < pageCount) {
        buttons.push(getPaginationButton(pageIndex + 3))
    }
    if (pageIndex + 4 === pageCount - 1) {
        buttons.push(getPaginationButton(pageIndex + 4))
    } else if (pageIndex + 4 < pageCount - 1) {
        buttons.push(getLastPaginationButton(pageCount - 1))
    }

    return (
        <div className="col-12 text-center mb-2">
            <div className="btn-group">{buttons}</div>
            <select
                className="pagination-select form-control ms-2"
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value))
                }}
            >
                {[10, 25, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Toon {pageSize}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default TablePagination
